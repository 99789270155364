import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import cardsSlice from "services/cardsSlice";
import layoutSlice from "services/layoutSlice";
import authSlice from "services/authSlice";
import categoriesSlice from "services/categoriesSlice";
import optionsSlice from "services/optionsSlice";
import languagesSlice from "services/languagesSlice";
import usersSlice from "services/usersSlice";
import citiesSlice from "services/citiesSlice";
import countriesSlice from "services/countries";
import moderatorsSlice from "services/moderatorsSlice";
import analyticsSlice from "services/analyticsSlice";
import dashboardSlice from "services/dashboardSlice";

const store = configureStore({
  reducer: {
    cards: cardsSlice,
    layout: layoutSlice,
    auth: authSlice,
    categories: categoriesSlice,
    options: optionsSlice,
    countries: countriesSlice,
    languages: languagesSlice,
    users: usersSlice,
    cities: citiesSlice,
    moderators: moderatorsSlice,
    analytics: analyticsSlice,
    dashboard: dashboardSlice,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
