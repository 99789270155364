import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import SwitchComp from 'components/switchComp';
import { updateCountries } from 'services/countries/api';
import { useAppDispatch } from 'services';
import ReactCountryFlag from "react-country-flag"

const CountryCard = ({ item, setSelectedCountry, setAddCountry }: any) => {
  const dispatch = useAppDispatch();
  const { status } = item;
  const [checked, setChecked] = useState(status === "ACTIVE" ? true : false);
  const [backgroundColor, setBackgroundColor] = useState(status === "ACTIVE" ? "100%" : "50%");

  const handleUpdate = (checked: boolean) => {
    setChecked(checked);
    const newStatus = checked ? "ACTIVE" : "PASSIVE";
    setBackgroundColor(newStatus === "ACTIVE" ? "100%" : "50%");

    dispatch(
      updateCountries({
        country: {
          ...item,
          status: newStatus,
        },
      })
    );
  };
  return (
    <Box
    onClick={() => {
      setSelectedCountry(item);
      setAddCountry(true);
    }} 
      sx={{
        backgroundColor: "#424242",
        opacity: backgroundColor,
        borderRadius: "10px",
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
          <ReactCountryFlag
            countryCode={item.code}
            svg
            style={{
              aspectRatio: "auto 4 / 3",
            }}
            title={item.name}
          />
        <Typography
          sx={{
            color: "#fff",
            fontSize: "16px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          variant="buttonText"
        >
          {item.name}
        </Typography>
      </Box>
      <SwitchComp checked={checked} setChecked={handleUpdate} />
    </Box>
  );
};

export default CountryCard;
