import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "components/layout";
import { CardsWrapper, Header } from "./styles";
import { useAppDispatch, useAppSelector } from "services";
import { getCountries } from "services/countries";
import CountryCard from "./components/countryCard";
import CountryModal from "./components/countryModal";
import { Country } from "interfaces/country";
import { useLocation } from "react-router-dom";

const Countries = () => {
  const dispatch = useAppDispatch();
  const location = useLocation() as any;
  const [isAddCountry, setAddCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(getCountries({ user }));
  }, [dispatch, user]);

  const { countries } = useAppSelector((state) => state.countries);

  useEffect(() => {
    if (location?.state?.addCountry) {
      setAddCountry(true);
      window.history.replaceState({}, document.title);
    }
  }, [location?.state?.addCountry]);

  return (
    <Layout>
      <Header>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        ></Box>
        <Button
          variant="contained"
          onClick={() => {
            setSelectedCountry(undefined);
            setAddCountry(true);
          }}
        >
          <Typography variant="buttonText">+ Add New Country</Typography>
        </Button>
      </Header>
      <CardsWrapper>
        {countries?.map((item: any, index) => (
          <CountryCard
            key={`${item.title}-${index}`}
            setAddCountry={setAddCountry}
            setSelectedCountry={setSelectedCountry}
            item={item}
          />
        ))}
      </CardsWrapper>
      <CountryModal
            open={isAddCountry}
            onClose={() => setAddCountry(false)}
            country={selectedCountry}
          />
    </Layout>
  );
};

export default Countries;
