import "components/errors/unauthorized/style.css";

const Unauthorized = () => {
  return (
    <section className="page_404">
      <div className="page_container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center">401</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">401 - Unauthorized</h3>
                <p>
                  Oops! It looks like you don't have the required permissions to
                  access this page.
                </p>
                <a href="/" className="link_404">
                  Go to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Unauthorized;
