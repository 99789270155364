import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  CardFooter,
  CardWrapper,
  DownContentWrapper,
  TinyButton,
  TinyButtonWrapper,
  Title,
  UpContentLeft,
  UpContentWrapper,
} from "./styles";
import TinyCircleIcon from "assets/dashboard/TinyCircleIcon";
import TinyArrowIcon from "assets/dashboard/TinyArrowIcon";
import CircleDownArrowIcon from "assets/dashboard/CircleDownArrowIcon";
import UpArrowIcon from "assets/categories/UpArrowIcon";
import RightArrowIcon from "assets/categories/RightArrowIcon";
import LeftArrowIcon from "assets/categories/LeftArrowIcon";
import PhoneStats from "components/dashboardCards/phoneStats";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

type NumberOfUsersProps = {
  title?: string;
  bgcolor?: string;
  icon?: any;
  data?: {
    id?: any;
    title?: string;
    subtitle?: string;
    total?: number;
    icon?: any;
    image?: any;
    color?: string;
    thisWeek?: number;
    thisMonth?: number;
    thisYear?: number;
    optionRightStat?: number;
    optionLeftStat?: number;
    optionTopStat?: number;
    voteCard?: boolean;
  };
  appleStats?: number;
  androidStats?: number;
};

const NumberOfUsers: FC<NumberOfUsersProps> = ({
  title,
  bgcolor,
  icon,
  data,
  appleStats,
  androidStats,
}) => {
  const { t } = useTranslation(["dashboard"]);
  

  const initialExpanded = JSON.parse(localStorage.getItem("dashboardConfig") || "{}").expandedSettings || {};
  const [expanded, setExpanded] = useState<boolean>(initialExpanded[data?.id] || false);

  useEffect(() => {
    localStorage.setItem("dashboardConfig", JSON.stringify({
      ...JSON.parse(localStorage.getItem("dashboardConfig") || "{}"),
      expandedSettings: {
        ...initialExpanded,
        [data?.id]: expanded,
      },
    }));
    // eslint-disable-next-line
  }, [expanded]);

  const handleExpansion = () => {
    const newExpandedState = !expanded;
    setExpanded(newExpandedState);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpansion}
      elevation={0}
      sx={{
        width: "100%",
        position: "relative",
        padding: "0px",
        boxShadow: "none",
        borderRadius: "10px",
        backgroundColor: "transparent",
        "&.Mui-expanded": {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        sx={{
          padding: "0",
          minHeight: "0",
          margin: "0",
          "&.Mui-expanded": {
            minHeight: "0",
          },
        }}
        aria-controls={`panel-${title}`}
        id={`panel-${title}`}
      >
        <CardWrapper expanded={expanded?.toString()} bgcolor={data?.color || bgcolor}>
          <Title
            variant="cardTitle"
            voteCard={data?.voteCard ? true : false}
          >
            {data?.title || title}
          </Title>
          <TinyButtonWrapper>
            <TinyButton>
              <TinyArrowIcon style={{ transform: expanded ? "rotate(90deg)" : "rotate(0deg)", transition: "all 250ms ease" }} />
            </TinyButton>
            <TinyButton>
              <TinyCircleIcon />
            </TinyButton>
          </TinyButtonWrapper>
          <UpContentWrapper>
            <UpContentLeft>
              <Typography
                variant="cardContent"
                sx={{ fontSize: "12px" }}
                color={data?.voteCard ? "black" : "inherit"}
              >
                {data?.voteCard ? data?.subtitle : t("total-user-count")}
              </Typography>
              <Typography
                variant="cardNumber"
                color={data?.voteCard ? "black" : "inherit"}
                fontWeight="700"
              >
                {data?.total || 0}
              </Typography>
            </UpContentLeft>
            {data?.image ? <img src={data?.image} alt="" /> : data?.icon}
          </UpContentWrapper>
          <DownContentWrapper>
            {appleStats || androidStats ? (
              <PhoneStats appleStats={appleStats} androidStats={androidStats} />
            ) : (
              <CircleDownArrowIcon color={data?.voteCard ? "black" : "white"} style={{ transform: expanded ? "rotate(0deg)" : "rotate(-90deg)", transition: "all 250ms ease" }} />
            )}
            <Typography
              variant="cardContent"
              sx={{ ml: "32px" }}
              color={data?.voteCard ? "black" : "inherit"}
            >
              {new Date().toLocaleDateString("tr-TR", {} as any).replace(/\//g, ".")}
              &nbsp;|&nbsp;
              {new Date().toLocaleTimeString("tr-TR", {} as any).slice(0, 5)} 
            </Typography>
          </DownContentWrapper>
        </CardWrapper>
        <div style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "100%",
          height: "40px",
          backgroundColor: "#0000000d",
          zIndex: 1,
          display: expanded ? "block" : "none",
        }}></div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0",
        }}
      >
        <CardFooter
          height={40}
          style={{
            display: expanded ? "flex" : "none",
          
          }}
          bgcolor={data?.voteCard ? "#e5e5e5" : data?.color || bgcolor}
        >
          {data?.thisWeek ? (
            <Typography
              variant="cardContent"
              color={data?.voteCard ? "black" : "inherit"}
            >
              {t("this-week")}: +{data?.thisWeek || 0}
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <UpArrowIcon color="black" />
              <Typography
                variant="cardContent"
                color={data?.voteCard ? "black" : "inherit"}
              >
                {data?.optionTopStat}
              </Typography>
            </Box>
          )}
          {data?.thisMonth ? (
            <Typography
              variant="cardContent"
              color={data?.voteCard ? "black" : "inherit"}
            >
              {t("this-month")}: +{data?.thisMonth || 0}
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <RightArrowIcon color="black" />
              <Typography
                variant="cardContent"
                color={data?.voteCard ? "black" : "inherit"}
              >
                {data?.optionRightStat}
              </Typography>
            </Box>
          )}
          {data?.thisYear ? (
            <Typography
              variant="cardContent"
              color={data?.voteCard ? "black" : "inherit"}
            >
              {t("this-year")}: +{data?.thisYear || 0}
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <LeftArrowIcon color="black" />
              <Typography
                variant="cardContent"
                color={data?.voteCard ? "black" : "inherit"}
              >
                {data?.optionLeftStat}
              </Typography>
            </Box>
          )}
          <Typography
            variant="cardContent"
            color={data?.voteCard ? "black" : "inherit"}
          >
            /users
          </Typography>
        </CardFooter>
      </AccordionDetails>
    </Accordion>
  );
};

export default NumberOfUsers;
