import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import MeatballsMenuIcon from "assets/cards/MeatballsMenuIcon";
import TinyCloseIcon from "assets/cards/TinyCloseIcon";
import { IMG_URL } from "constants/index";
import { useAppDispatch } from "services";
import moment from "moment";

import {
  CardImage,
  Wrapper,
  CardHeader,
  CardHeaderItem,
  EnableCardButton,
  CardBack,
  CardImageWrapper,
  CardBackItemTitle,
} from "./styles";
import { useNavigate } from "react-router-dom";
import WarnModal from "components/warnModal";
import { Card } from "interfaces/card";
import { updateCard } from "services/cardsSlice";
import { useTranslation } from "react-i18next";

type ContentCardProps = {
  imgsrc: string;
  cardIndex: number;
  active: boolean;
  title: string;
  data: Card;
  handleDeleteCard?: any;
};

const ContentCard: FC<ContentCardProps> = ({
  imgsrc,
  cardIndex,
  active,
  title,
  data,
  handleDeleteCard,
}) => {
  const navigate = useNavigate();
  const [showBack, setShowBack] = useState(false);
  const [isActive, setActive] = useState(active);
  const [isDeleteWarn, setDeleteWarn] = useState(false);
  const [isActiveWarn, setActiveWarn] = useState(false);

  const handleCardClick = () => {
     setShowBack(!showBack);
  };
  const dispatch = useAppDispatch();
  const getDateTime = () => {
    return moment(data.releaseStartDate).format("DD/MM/YYYY - HH:mm:ss");
  };

  const { t } = useTranslation(["cards"]);

  const { optionTopStat, optionLeftStat, optionRightStat } = data;
  const totalStat = optionTopStat + optionLeftStat + optionRightStat;
  const leftStat = ((optionLeftStat * 100) / totalStat || 0).toFixed(1);
  const rightStat = ((optionRightStat * 100) / totalStat || 0).toFixed(1);
  const optionTop = ((optionTopStat * 100) / totalStat || 0).toFixed(1);
  const totalStatView = (stat: number) => {
    if (stat < 1000) {
      return stat;
    }
    if (stat >= 1000 && stat < 1000000) {
      return (stat / 1000).toFixed(1) + "K";
    }
    if (stat >= 1000000 && stat < 1000000000) {
      return (stat / 1000000).toFixed(1) + "M";
    }
  };
  return (
    <Wrapper>
      <CardHeader>
        <CardHeaderItem>
          <Typography color="#000" variant="paragraph">
            {data.categoryTitle}
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: -4,
              right: 0,
            }}
          >
            <MeatballsMenuIcon
              color={showBack ? "#FF9F27" : ""}
              onClick={handleCardClick}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </CardHeaderItem>
        <CardHeaderItem>
          <Typography variant="subContent">{getDateTime()}</Typography>
          <EnableCardButton borderColor={isActive ? "#34c759" : "#C2C2C2"}>
            <Typography color={isActive ? "#34c759" : "#C2C2C2"}>
              {isActive ? t('active') : t('passive')}
            </Typography>
          </EnableCardButton>
        </CardHeaderItem>
        <CardHeaderItem>
          <Typography variant="subContent">
            <Typography color="#ED0505" variant="subContent">
              %{leftStat} ({optionLeftStat})
            </Typography>
            <Typography variant="subContent">
              {" "}
              - %{optionTop} ({optionTopStat}) -{" "}
            </Typography>
            <Typography color="#34c759" variant="subContent">
              %{rightStat} ({optionRightStat})
            </Typography>
          </Typography>
          <Typography variant="subContent">
            {t('total')}: {totalStatView(totalStat)}
          </Typography>
        </CardHeaderItem>
      </CardHeader>
      <CardImageWrapper>
        {!isActive && (
          <Box
            sx={{
              width: "100%",
              height: "446px",
              background: "#ffffff99",
              borderRadius: "8px",
              position: "absolute",
              zIndex: 10,
            }}
          />
        )}
        <CardImage imgsrc={imgsrc}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <img
              src={IMG_URL + data.categoryMedia}
              alt="category-icon"
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                background: "#fff",
              }}
            />
            <Typography variant="cardCategoryTitle">
              {data.categoryTitle}
            </Typography>
          </Box>
          <Typography
            sx={{
              position: "absolute",
              bottom: "56px",
              width: "calc(100% - 32px)",
              zIndex: 2,
            }}
            fontWeight={500}
            variant="cardCategoryTitle"
          >
            {title}
          </Typography>
          <Box
            sx={{
              position: "absolute",
              bottom: "16px",
              width: "calc(100% - 32px)",
              zIndex: 2,
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Typography
              sx={{ textAlign: "left" }}
              color="#ED0505"
              variant="subContent"
              fontWeight={700}
            >
              {data?.optionLeftName}  <br/>
              {leftStat}
              {"%"}
            </Typography>
            <Typography
              sx={{ textAlign: "center" }}
              color="#C2C2C2"
              variant="subContent"
              fontWeight={700}
            >
              {data?.optionTopName || t("indecisive")}  <br/> {optionTop}%
            </Typography>
            <Typography
              sx={{ textAlign: "right" }}
              color="#34c759"
              variant="subContent"
              fontWeight={700}
            >
              {data?.optionRightName}  <br/> {rightStat}%
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              position: "absolute",
              bottom: 0,
              left: 0,
              heiht: 0,
              height: 200,
              zIndex: 0,
              background:
                "linear-gradient(0deg, #000 0%, #000 0%, transparent 100%)",
            }}
          />
        </CardImage>

        <CardBack showback={showBack ? 1 : 0} sx={{ zIndex: 100 }}>
          <Box
            sx={{ position: "absolute", right: "16px", cursor: "pointer" }}
            onClick={() => setShowBack(false)}
          >
            <TinyCloseIcon />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardBackItemTitle
              onClick={() =>
                navigate("/cards/addCard", {
                  state: {
                    data,
                  },
                })
              }
            >
              {t('edit-card')}
            </CardBackItemTitle>
            <Box
              sx={{ width: "80%", borderBottom: "1px solid black", my: "30px" }}
            />
            <CardBackItemTitle
              onClick={() =>
                navigate("/cards/share", {
                  state: {
                    data,
                  },
                })
              }
            >
              {t('share-card')}
            </CardBackItemTitle>
            <Box
              sx={{ width: "80%", borderBottom: "1px solid black", my: "30px" }}
            />
            <CardBackItemTitle
              onClick={() => setActiveWarn(true)}
            >
              {isActive ? t('set-passive') : t('set-active')}
            </CardBackItemTitle>
            <Box
              sx={{ width: "80%", borderBottom: "1px solid black", my: "30px" }}
            />
            <CardBackItemTitle
              sx={{ cursor: "pointer" }}
            >
              {t('move-archive')}
            </CardBackItemTitle>
            <Box
              sx={{ width: "80%", borderBottom: "1px solid black", my: "30px" }}
            />
            <CardBackItemTitle
              onClick={() => setDeleteWarn(true)}
            >
              {t('delete-card')}
            </CardBackItemTitle>
          </Box>
        </CardBack>
      </CardImageWrapper>

      <WarnModal
        open={isDeleteWarn}
        onClose={() => setDeleteWarn(false)}
        title={t('modal-delete-title')}
        description={t('modal-delete-description')}
        onSubmit={() => {
          handleDeleteCard(data.id);
          setShowBack(false);
        }}
      />
      <WarnModal
        open={isActiveWarn}
        onClose={() => setActiveWarn(false)}
        title={isActive ? t('set-passive') : t('set-active')}
        description={
          isActive
            ? t('modal-active-is-passive')
            : t('modal-active-is-active')
        }
        onSubmit={() => {
          dispatch(
            updateCard({
              body: { ...data, status: isActive ? "PASSIVE" : "ACTIVE" },
            })
          );
          setActive(!isActive);
        }}
      />
    </Wrapper>
  );
};

export default ContentCard;
