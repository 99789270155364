import React from "react";
import { Box } from "@mui/material";
import CustomTextFiled from "components/customTextField";
import { useTranslation } from 'react-i18next';

type CardTitleDescriptionProps = {
  title: string;
  description: string;
  setData: any;
};

const CardTitleDescription: React.FC<CardTitleDescriptionProps> = ({
  title,
  description,
  setData,
}) => {
  const { t } = useTranslation(['cards']);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <CustomTextFiled
        id="title"
        name="title"
        label={t('title')}
        value={title}
        onChange={setData}
      />

      {/* zorunlu değil */}

      <CustomTextFiled
        id="description"
        name="description"
        label={t('description')}
        value={description}
        onChange={setData}
        multiline
        minRows={10}
        maxRows={10}
      />
    </Box>
  );
};

export default CardTitleDescription;
