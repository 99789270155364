import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { HttpRequest, RequestType } from "plugins/httpRequest";

export const getUsersByCountry = createAsyncThunk(
  "dashboard",
  async ({ countryId }: { countryId: string }, { getState }) => {
    const state = getState() as RootState;
    const response = HttpRequest<any, any>({
      url: `Admin/Users/GetAll`,
      method: RequestType.POST,
      body: {
        TYPE: "and",
        items: [
          {
            NAME: "countryId",
            Value: state.countries.countryId,
            TYPE: "=",
          },
          {
            NAME: "role",
            VALUES: ["USER", "ANON"],
            TYPE: "in",
          },
        ],
      },
    });
    return response;
  }
);
