import { Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import ArchivesMenuIcon from "assets/archives/ArchivesMenuIcon";
import CloseIcon from "assets/categories/CloseIcon";
import LeftArrowIcon from "assets/categories/LeftArrowIcon";
import RightArrowIcon from "assets/categories/RightArrowIcon";
import UpArrowIcon from "assets/categories/UpArrowIcon";
import SwitchComp from "components/switchComp";
//@ts-ignore
import { ColorExtractor } from "react-color-extractor";

import { Wrapper } from "./styles";
import { Category } from "interfaces/category";
import { IMG_URL } from "constants/index";
import { useAppDispatch } from "services";
import { updateCategories } from "services/categoriesSlice";

type ArchiveCardProps = {
  item: Category;
  onClose: any;
};

const ArchiveCard: FC<ArchiveCardProps> = ({ item, onClose }) => {
  const { status, media, title } = item;
  const [checked, setChecked] = useState(status === "ACTIVE");
  const [bgColor, setBgColor] = useState("#00000080");
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        onClick={onClose}
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          cursor: "pointer",
          padding: "8px 16px",
          zIndex: 100,
        }}
      >
        <CloseIcon color="#fff" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 100,
          right: "167px",
          top: "16px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        <SwitchComp
          checked={checked}
          setChecked={(val: boolean) => {
            setChecked(val);
            dispatch(
              updateCategories({
                category: {
                  ...item,
                  status: !checked ? "ACTIVE" : "PASSIVE",
                },
              })
            );
          }}
        />
      </Box>

      {!checked && (
        <Wrapper
          sx={{ position: "absolute", bgcolor: "#ffffff99", zIndex: 10 }}
        />
      )}

      <Wrapper sx={{ bgcolor: bgColor, color: "white" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <ColorExtractor getColors={(colors: any) => setBgColor(colors[2])}>
            <img
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={IMG_URL + media}
              alt="category-icon"
            />
          </ColorExtractor>
          <Typography variant="cardNumber" sx={{ fontWeight: "400", flex: 1 }}>
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "54px",
            paddingRight: "160px",
            marginTop: "16px",
          }}
        >
          <Typography variant="cardContent">
            {item.totalCount} İlgili kart ({item.releasingCount} aktif -{" "}
            {item.totalCount - item.releasingCount} pasif)
          </Typography>
          <Typography variant="cardContent">11.06.2022 15:45:11</Typography>
          <Typography variant="cardContent">10 points</Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            background: "#5A5A5A80",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "48px",
            paddingRight: "174px",
          }}
        >
          <Box
            sx={{
              padding: "6px 20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "358px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <UpArrowIcon />
              <Typography variant="cardContent">
                {item.optionTopStat}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <RightArrowIcon />
              <Typography variant="cardContent">
                {item.optionRightStat}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <LeftArrowIcon />
              <Typography variant="cardContent">
                {item.optionLeftStat}
              </Typography>
            </Box>
            <Typography variant="cardContent">/vote</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "358px",
            }}
          >
            <Typography variant="cardContent">
              This week: +{item.lastWeekVoteCount}
            </Typography>
            <Typography variant="cardContent">
              This month: +{item.lastMonthVoteCount}
            </Typography>
            <Typography variant="cardContent">
              This year: +{item.lastYearVoteCount}
            </Typography>
            <Typography variant="cardContent">/users</Typography>
          </Box>
          <ArchivesMenuIcon style={{ position: "absolute", right: 12 }} />
        </Box>
      </Wrapper>
    </Box>
  );
};

export default ArchiveCard;
