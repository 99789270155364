import { useState, useEffect } from "react";
import NumberOfUsers from 'components/dashboardCards/numberOfUsers/index';
import { IMG_URL } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'services/index';

const BestCard = () => {
    const {t} = useTranslation(['dashboard']);
    const { countryId } = useAppSelector((state) => state.countries);

    const [topVotedCard, setTopVotedCard] = useState<any>(null);
    const [cardData, setCardData] = useState<any>(null);

    const token = JSON.parse(window.localStorage.getItem("token") || "");

  const getTopCard = () => {
    fetch(`https://api.svayp.com/Admin/VotesView/GetDynamic`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        "Columns":[
            {
                "Name":"cardTitle"
            },
            {
                "Alias":"leftStat",
                "Name":"optionLeftStat",
                "AggregateFunction":"sum"
            },
            {
                "Alias":"rightStat",
                "Name":"optionRightStat",
                "AggregateFunction":"sum"
            },
            {
                "Alias":"topStat",
                "Name":"optionTopStat",
                "AggregateFunction":"sum"
            },
             {
                "Name":"cardId"
            },
            {
                "Alias":"count",
                "Name":"id",
                "AggregateFunction": "count"
            }
        ],
        "Where":{
                "NAME": "countryId",
                "VALUE": countryId,
                "TYPE": "="
            },
              "OrderColumns": [
            {
                "Name": "count",
                "isDescending": true
            }
        ],
        "Take": 1
    }
    )
    })
      .then((response) => response.json())
      .then((data) => {
        setTopVotedCard(data.data[0]);
      })
  };

  const getCardMedia = () => {
    fetch(`https://api.svayp.com/Admin/Cards/GetById/${topVotedCard?.cardId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setCardData(data)
        })
}

  useEffect(() => {
    getTopCard();
    // eslint-disable-next-line
  }, [countryId]);
  
  useEffect(() => {
    getCardMedia();
    // eslint-disable-next-line
}, [topVotedCard]);

    const topVotedCardData = {
    id: 'topVotedCard',
    title: t('top-voted-card'),
    subtitle: topVotedCard?.cardTitle.substring(0, 25) + '...',
    total: topVotedCard?.leftstat + topVotedCard?.rightstat + topVotedCard?.topstat,
    image: IMG_URL + cardData?.media ,
    color: "#c7c7c7",
    optionTopStat: topVotedCard?.topstat,
    optionLeftStat: topVotedCard?.leftstat,
    optionRightStat: topVotedCard?.rightstat,
    voteCard: true,
  }

  return (
    <NumberOfUsers data={topVotedCardData} />
  )
}

export default BestCard