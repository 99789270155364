import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { User } from "interfaces/user";
import MeatballsMenuIcon from "assets/cards/MeatballsMenuIcon";
import { useAppDispatch } from "services";
import {
  deleteUser,
  getUsers
} from "services/usersSlice/api";
import { toast } from "react-toastify";

type CustomMenuProps = {
  item: User;
  page: number;
};

export const CustomMenu = ({ item, page }: CustomMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openId, setOpenId] = React.useState<null | string>(null);

  const dispatch = useAppDispatch();
  const handleClick = (item: User, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenId(item.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenId(null);
  };

  const handleDelete = (item: any) => {
    setAnchorEl(null);
    setOpenId(null);
    dispatch(deleteUser(item.id))
      .then((res) => {
        dispatch(
          getUsers({
            page: page,
            pageSize: 8,
          })
        );
      })
      .then(() => {
        toast.success("User deleted successfully.");
      })
      .catch(() => {
        toast.success("Something went wrong.");
      });
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", cursor: "pointer" }}>
        <MeatballsMenuIcon
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            handleClick(item, event)
          }
          aria-controls={
            openId === item.id ? `account-menu-${item.id}` : undefined
          }
          aria-haspopup="true"
          aria-expanded={openId === item.id ? "true" : undefined}
        ></MeatballsMenuIcon>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openId === item.id}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => handleDelete(item.id)}>Delete</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
