import React from "react";
import {
  Container,
  CardContainer,
  PreviewContainer,
  SettingsContainer,
} from "./styles";
import { useToPng } from "@hugocxl/react-to-image";
import {
  Box,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
} from "@mui/material";
import InstagramStoryContentCard from "pages/cards/components/shareCard/components/templates/instagram/story";
import { IMG_URL } from "constants/index";
import DownloadIcon from "@mui/icons-material/Download";

const InstagramStoryTab = ({
    dataq,
}:any) => {
    const [showVoteData, setShowVoteData] = React.useState(true);
    const [showGridLine, setShowGridLine] = React.useState(false);
  
    const handleViewVoteData = () => {
      setShowVoteData(!showVoteData);
    };
  
    const handleViewGridLine = () => {
      setShowGridLine(!showGridLine);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, convert, ref] = useToPng<HTMLDivElement>({
        quality: 1,
        canvasWidth: 2160,
        canvasHeight: 3840,
        onSuccess: (data: any) => {
          const link = document.createElement("a");
          link.download = 'svayp-card-' + new Date().getTime() + ".png";
          link.href = data;
          link.click();
        },
      });

  return (
    <Container>
    <PreviewContainer>
      <Typography sx={{ marginBottom: 4 }} variant="h6">
        Preview Card
      </Typography>
      <CardContainer ref={ref}>
        <InstagramStoryContentCard
          // imgsrc={`https://picsum.photos/450/340?random=${page}`}
          // imgsrc={`https://source.unsplash.com/random/450x340?sig=${cardIndex}`}
          // imgsrc={`https://svaypprodbucket.s3.eu-central-1.amazonaws.com/${item.media}`}
          imgsrc={IMG_URL + dataq.media}
          key={`card-${1}`}
          active={dataq.status === "ACTIVE"}
          title={dataq.title}
          data={dataq}
          showVoteData={showVoteData}
          showGridLine={showGridLine}
        />
      </CardContainer>
    </PreviewContainer>
    <SettingsContainer>
      <Typography sx={{ marginBottom: 4 }} variant="h6">
        Settings
      </Typography>
    <Box
    display={"flex"}
    gap={10}
    >
    <div>
        <FormControl>
          <FormLabel id="vote-visibility">Vote Visibliity</FormLabel>
          <RadioGroup onChange={handleViewVoteData}>
            <FormControlLabel
              checked={showVoteData}
              value="view"
              control={<Radio />}
              label="View Vote Data"
            />
            <FormControlLabel
              checked={!showVoteData}
              value="mask"
              control={<Radio />}
              label="Mask Vote Data"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <FormControl>
          <FormLabel id="vote-visibility">Grid Visibliity</FormLabel>
          <RadioGroup onChange={handleViewGridLine}>
            <FormControlLabel
              checked={showGridLine}
              value="view"
              control={<Radio />}
              label="Show"
            />
            <FormControlLabel
              checked={!showGridLine}
              value="mask"
              control={<Radio />}
              label="Hide"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </Box>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={convert}>
          <DownloadIcon
            sx={{ color: "#fff", marginRight: 0.5, fontSize: 18 }}
          />
          <Typography variant="buttonText">Download</Typography>
        </Button>
      </div>
    </SettingsContainer>
  </Container>
  )
}

export default InstagramStoryTab