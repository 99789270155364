import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getLanguages } from "./api";
export { getLanguages };

export interface LanguagesState {
  languages: any[];
  languageId: string;
  loading: boolean;
  error: any;
}

const initialState: LanguagesState = {
  languages: [],
  languageId: "1c76def1-2575-4f7f-bf54-968d31fd144b",
  loading: false,
  error: "",
};

export const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    setLanguageId: (state, action) => {
      state.languageId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLanguages.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getLanguages.fulfilled, (state, action: any) => {
        state.languages = action.payload.data;
        state.loading = false;
      })
      .addCase(getLanguages.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching languages data!";
        toast.error("Get languages failed!");
      });
  },
});

export const { setLanguageId } = languagesSlice.actions;

export default languagesSlice.reducer;
