import React from "react";
import { Box } from "@mui/material";
import CustomTextFiled from "components/customTextField";
import { useTranslation } from 'react-i18next';

type CardPointsProps = {
  categoryPoint: number;
  cardPoint: number;
  setData: any;
};

const CardPoints: React.FC<CardPointsProps> = ({
  categoryPoint,
  cardPoint,
  setData,
}) => {

  const { t } = useTranslation(['cards']);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "16px",
      }}
    >
      <CustomTextFiled
        id="categoryPoint"
        name="categoryPoint"
        label={t('category-point')}
        value={categoryPoint}
        onChange={setData}
        disabled
      />
      <CustomTextFiled
        id="cardPoint"
        name="cardPoint"
        label={t('card-point')}
        value={cardPoint}
        onChange={setData}
      />
    </Box>
  );
};

export default CardPoints;
