import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import loginPageTR from "languages/login-page/tr.json";
import loginPageUS from "languages/login-page/us.json";
import loginPageFR from "languages/login-page/fr.json";

import layoutTR from "languages/layout/tr.json";
import layoutUS from "languages/layout/us.json";
import layoutFR from "languages/layout/fr.json";

import dashboardTR from "languages/dashboard/tr.json";
import dashboardUS from "languages/dashboard/us.json";
import dashboardFR from "languages/dashboard/fr.json";

import cardsTR from "languages/cards/tr.json";
import cardsUS from "languages/cards/us.json";
import cardsFR from "languages/cards/fr.json";

const resources = {
  tr: {
    cards: cardsTR,
    dashboard: dashboardTR,
    layout: layoutTR,
    loginPage: loginPageTR,
  },
  us: {
    cards: cardsUS,
    dashboard: dashboardUS,
    layout: layoutUS,
    loginPage: loginPageUS,
  },
  fr:{
    cards: cardsFR,
    dashboard: dashboardFR,
    layout: layoutFR,
    loginPage: loginPageFR,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "tr",
    debug: false,
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
