import React, {useRef} from 'react';
import Layout from "components/layout";
import { changeLogsData } from "mock/changeLogs";

import {
  Container,
  Date,
  Description,
  DetailBox,
  Feature,
  IconBox,
  IconTextBox,
  Left,
  Prefix,
  Right,
  TimleLine,
  Title,
  Version,
  VersionBox,
} from "./styles";
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import ConstructionIcon from "@mui/icons-material/Construction";
import SecurityIcon from '@mui/icons-material/Security';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const ChangeLogs = () => {
    const windowWidth = useRef(window.innerWidth);
  return (
    <Layout>
      <Container>
        {changeLogsData.map((item, index) => (
          <VersionBox key={index}>
            <Left>
              <Version>Version {item.version}</Version>
              <Date>{item.date}</Date>
              <TimleLine />
            </Left>
            <Right>
              <Title>
                {windowWidth.current < 768 && <span style={{fontSize: "16px",color: "#3d3d3d",}}>Version {item.version} <br /></span>}
                {item.title}
                </Title>
              <Description>{item.description}</Description>
              {item.detail.map((feature, index) => (
                <DetailBox key={index}>
                  <Prefix type={feature.type}>
                    <IconBox  type={feature.type}>
                     { feature.type === "Added" &&  <AddIcon /> }
                     { feature.type === "Updated" &&  <UpdateIcon /> }
                     { feature.type === "Edited" &&  <EditIcon /> }
                     { feature.type === "Deleted" &&  <RemoveIcon /> }
                     { feature.type === "Fixed" &&  <ConstructionIcon /> }
                     { feature.type === "Security" &&  <SecurityIcon /> }
                     { feature.type === "Changed" &&  <SyncAltIcon /> }
                     { feature.type === "Renamed" &&  <DriveFileRenameOutlineIcon /> }
                    </IconBox>
                    <IconTextBox>{feature.type}</IconTextBox>
                  </Prefix>
                  <Feature dangerouslySetInnerHTML={{__html: feature.feature}}></Feature>
                </DetailBox>
              ))}
            </Right>
          </VersionBox>
        ))}
      </Container>
    </Layout>
  );
};

export default ChangeLogs;
