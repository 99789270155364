export interface User {
  firstName: string;
  lastName: string;
  role: UserRole;
  email: string;
  deviceToken: string;
  deviceModel: string;
  deviceOs: string;
  status: "ACTIVE" | "PASSIVE";
  languageId: string;
  allowLocation: boolean;
  allowNotification: boolean;
  birthDay: string;
  birthPlaceId: string;
  countryId: string;
  cityId: string;
  district: string;
  address: string;
  phoneCountryCode: string;
  cityName: string;
  birthPlaceName: string;
  maritalStatusName: string;
  professionName: string;
  educationLevelName: string;
  footballTeamName: string;
  jobStatusName: string;
  incomeGroupName: string;
  genderIdentityName: string;
  genderName: string;
  zodiacSignName: string;
  ethnicGroupName: string;
  phone: string;
  clientId: string;
  maritalStatusId: string;
  professionId: string;
  educationLevelId: string;
  footballTeamId: string;
  jobStatusId: string;
  incomeGroupId: string;
  genderIdentityId: string;
  genderId: string;
  zodiacSignId: string;
  ethnicGroupId: string;
  lastSchoolName: string;
  kids: string;
  points: number;
  phoneVerified: boolean;
  emailVerified: boolean;
  id: string;
  level: UserLevel;
  registeredDate: string;
  managedCountries: string[];
  managedCategories: string[];
  password: any;
}

export enum UserRole {
  ALLUSERS = "",
  ANON = "ANON",
  USER = "USER",
  EDITOR = "EDITOR",
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
}

export enum UserLevel {
  ALLLEVEL = -1,
  ANON = 0,
  VERIFIED = 1,
  ADVANCED = 2,
}

export enum UserStatus {
  ALLSTATUS = "",
  ACTIVE = "ACTIVE",
  PASSIVE = "PASSIVE",
}

export const UserLevelText = ["Anon User", "Verified User", "Advanced User"];

export type AddModeratorDTO = Pick<
  User,
  "firstName" | "lastName" | "email" | "role" | "countryId" | "managedCategories" | "managedCountries" | "password"
>; /*&  {
  password: string,
}
 */
