import React from "react";
import Layout from "components/layout";
import { Link, useLocation } from "react-router-dom";
import { Card } from "interfaces/card";
import { useToPng } from "@hugocxl/react-to-image";
import {
  Box,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import InstagramPostTab from "./components/tabs/instagramPost";
import InstagramStoryTab from "./components/tabs/instagramStory";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ShareCard = () => {
  const location = useLocation() as { state: { data: Card } };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, convert, ref] = useToPng<HTMLDivElement>({
    quality: 1,
    canvasWidth: 2160,
    canvasHeight: 2160,
    onSuccess: (data: any) => {
      const link = document.createElement("a");
      link.download = 'svayp-card-' + new Date().getTime() + ".png";
      link.href = data;
      link.click();
    },
  });



  const dataq = location?.state?.data;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Layout>
      {location?.state?.data ? (
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex", marginTop: "30px"}}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Instagram Post" {...a11yProps(0)} />
            <Tab label="Instagram Story" {...a11yProps(1)} />
            <Tab label="Twitter Post" {...a11yProps(2)} />
            <Tab label="Whatsapp" {...a11yProps(3)} />
            <Tab label="Copy Link" {...a11yProps(4)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <InstagramPostTab dataq={dataq} />
          </TabPanel>
          <TabPanel value={value} index={1}>
        <InstagramStoryTab dataq={dataq} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
          <TabPanel value={value} index={3}>
            Item Four
          </TabPanel>
          <TabPanel value={value} index={4}>
            Item Five
          </TabPanel>
          <TabPanel value={value} index={5}>
            Item Six
          </TabPanel>
        </Box>
      ) : (
        <h2>
          Lütfen paylaşılacak <Link to="/cards">kartı seçin</Link>
        </h2>
      )}
    </Layout>
  );
};

export default ShareCard;
