import {
  Box,
  Button,
  inputLabelClasses,
  OutlinedInputProps,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LoginBgTR from "assets/login/loginBgTR.png";
import LoginBgEN from "assets/login/loginBgEN.png";
import LoginBgDE from "assets/login/loginBgDE.png";
import LoginBgRU from "assets/login/loginBgRU.png";
import LoginBgES from "assets/login/loginBgES.png";
import SvaypLogoBig from "assets/login/svayp-logo-big.png";

import { LoginWrapper, LoginCard, BottomTextWrapper } from "./styles";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "services";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import { changePassowrd } from "services/authSlice/api";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const { t, i18n } = useTranslation();
  const changeLanguage = () => {
    i18n.language === "en"
      ? i18n.changeLanguage("tr")
      : i18n.changeLanguage("en");
  };

  const sliderImages = [LoginBgTR, LoginBgEN, LoginBgDE, LoginBgRU, LoginBgES];

  const handleChangePassword = () => {
    dispatch(changePassowrd({ email, password, secretKey }))
      .then((res) => {
        toast.success("Your password changed.");
        navigate("/");
      })
      .catch((e) => {
        toast.error("Something went wrong!");
      });
  };

  const settings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    // swipe: false,
  };

  return (
    <Box
      sx={{
        width: "100wv",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: "65%",
        }}
      >
        <Slider {...settings}>
          {sliderImages.map((item: any, index: number) => (
            <Box
              key={`login-bg-${index}`}
              sx={{
                width: "100%",
                height: "100vh",
                objectFit: "cover",
              }}
              component="img"
              src={item}
            />
          ))}
        </Slider>
      </Box>
      <LoginWrapper>
        <LoginCard>
          <Box
            sx={{
              width: "128px",
              height: "32px",
            }}
            component="img"
            src={SvaypLogoBig}
          />
          <Typography
            variant="title1"
            style={{ display: "inline-block", whiteSpace: "pre-line" }}
          >
            {t("loginPage.resetPasswordTitle")}
          </Typography>
          <Typography variant="subTitle1">{t("loginPage.subtitle")}</Typography>
          <TextField
            id="e-mail"
            label={t("loginPage.email")}
            variant="filled"
            onChange={(e) => setEmail(e.target.value)}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <TextField
            id="password"
            label={t("loginPage.password")}
            type="password"
            variant="filled"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />

          <TextField
            id="secretKey"
            label={"Secret Key"}
            variant="filled"
            onChange={(e) => setSecretKey(e.target.value)}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <Button variant="contained" onClick={handleChangePassword}>
            <Typography variant="buttonText">
              {t("loginPage.changePassword")}
            </Typography>
          </Button>
        </LoginCard>
        <BottomTextWrapper>
          <Typography variant="subContent">{t("rights")}</Typography>
          <Typography
            variant="subContent"
            onClick={changeLanguage}
            sx={{
              border: "0.5px solid #c2c2c2",
              borderRadius: "4px",
              padding: "2px 3px",
              cursor: "pointer",
            }}
          >
            {i18n.language === "en" ? "EN" : "TR"}
          </Typography>
        </BottomTextWrapper>
      </LoginWrapper>
    </Box>
  );
};

export default ChangePassword;
