import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants/index";
import { HttpRequest, RequestType } from "plugins/httpRequest";

export type Option = {
  name: string;
  languageId: string;
  status: "ACTIVE" | "PASSIVE";
  position: "LEFT" | "RIGHT" | "TOP";
  id: string;
};
export type AddOption = Pick<
  Option,
  "name" | "languageId" | "status" | "position"
>;

export const getOptions = createAsyncThunk(
  "options",
  async ({ token, languageId }: { token: string; languageId: string }) => {
    const myHeaders = new Headers({
      Authorization: token,
      "Content-Type": "application/json",
    });

    const myBody = JSON.stringify({
      Type: "and",
      Items: [{ Name: "languageId", Value: languageId, Type: "=" }],
    });

    const response = await fetch(
      `${API_URL}Admin/Options/GetAll?orderBy=name `,
      {
        headers: myHeaders,
        method: "POST",
        body: myBody,
      }
    );
    const result = await response.json();
    return result;
  }
);

export const addOption = createAsyncThunk(
  "addOption",
  async function ({ option }: { option: any }) {
    const res = HttpRequest<AddOption, Option>({
      url: `Admin/Options/Add`,
      method: RequestType.POST,
      body: option,
    });

    return res;
  }
);
