import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { IMG_URL } from "constants/index";

import { CardImage, Wrapper, CardImageWrapper } from "./styles";
import { Card } from "interfaces/card";
import { useTranslation } from "react-i18next";

type InstagramNormalPostProps = {
  imgsrc: string;
  active: boolean;
  title: string;
  data: Card;
  showVoteData: boolean;
  showGridLine?: boolean;
};

const InstagramNormalPostContentCard: FC<InstagramNormalPostProps> = ({
  imgsrc,
  title,
  data,
  showVoteData,
  showGridLine = false,
}) => {
  const { t } = useTranslation(["cards"]);

  const { optionTopStat, optionLeftStat, optionRightStat } = data;
  const totalStat = optionTopStat + optionLeftStat + optionRightStat;
  const leftStat = ((optionLeftStat * 100) / totalStat || 0).toFixed(1);
  const rightStat = ((optionRightStat * 100) / totalStat || 0).toFixed(1);
  const optionTop = ((optionTopStat * 100) / totalStat || 0).toFixed(1);
  return (
    <Wrapper>
      <CardImageWrapper>
        <CardImage imgsrc={imgsrc}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <img
              src={IMG_URL + data.categoryMedia}
              alt="category-icon"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                background: "#fff",
                zIndex: 2,
              }}
            />
            <Typography zIndex={2} fontSize={13} variant="cardCategoryTitle">
              {data.categoryTitle}
            </Typography>
          </Box>
          <Typography
            sx={{
              position: "absolute",
              bottom: "68px",
              width: "calc(100% - 53px)",
              zIndex: 2,
            }}
            fontWeight={600}
            fontSize={19}
            lineHeight={"23px"}
            fontFamily={"Poppins"}
            variant="cardCategoryTitle"
          >
            {title}
          </Typography>
          <Box
            sx={{
              position: "absolute",
              bottom: "24px",
              zIndex: 2,
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: 1,
              width: "calc(100% - 53px)",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Typography
              sx={{ textAlign: "left" }}
              color="#ED0505"
              variant="subContent"
              fontWeight={700}
              fontSize={12}
            >
              {data?.optionLeftName} <br />
              {showVoteData ? leftStat : 0}
              {"%"}
            </Typography>
            <Typography
              sx={{ textAlign: "center" }}
              color="#C2C2C2"
              variant="subContent"
              fontWeight={700}
              fontSize={12}
            >
              {data?.optionTopName || t("indecisive")} <br />{" "}
              {showVoteData ? optionTop : 0}%
            </Typography>
            <Typography
              sx={{ textAlign: "right" }}
              color="#34c759"
              variant="subContent"
              fontWeight={700}
              fontSize={12}
            >
              {data?.optionRightName} <br /> {showVoteData ? rightStat : 0}%
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              position: "absolute",
              bottom: 0,
              left: 0,
              height: 165,
              zIndex: 0,
              background:
                "linear-gradient(0deg, #FFFFFF 0%, #050505 0%, transparent 100%)",
            }}
          />
          <Box
            sx={{
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              height: 85,
              zIndex: 0,
              background:
                "linear-gradient(180deg, #FFFFFF 0%, #050505 0%, transparent 100%)",
            }}
          />
          {showGridLine && (
            <Box
              sx={{
                width: "27px",
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                zIndex: 0,
                background: "red",
                opacity: 0.2,
                borderRight: "1px dashed white",
              }}
            />
          )}

          {showGridLine && (
            <Box
              sx={{
                width: "27px",
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                zIndex: 0,
                background: "red",
                opacity: 0.2,
                borderLeft: "1px dashed white",
              }}
            />
          )}
          {showGridLine && (
            <Box
              sx={{
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                height: "27px",
                zIndex: 0,
                background: "red",
                opacity: 0.2,
                borderBottom: "1px dashed white",
              }}
            />
          )}
          {showGridLine && (
            <Box
              sx={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                left: 0,
                height: "27px",
                zIndex: 0,
                background: "red",
                opacity: 0.2,
                borderTop: "1px dashed white",
              }}
            />
          )}
        </CardImage>
      </CardImageWrapper>
    </Wrapper>
  );
};

export default InstagramNormalPostContentCard;
