import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpRequest, RequestType } from "plugins/httpRequest";

export const getLanguages = createAsyncThunk(
  "languages",
  async (_, { rejectWithValue }) => {
    try {
      const res = await HttpRequest<null, null>({
        url: `Admin/CountriesLanguagesView/GetAll`,
        method: RequestType.GET,
      });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
