import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Category,
  CreateCategory,
  UpdateCategory,
} from "interfaces/category";
import { HttpRequest, RequestType } from "plugins/httpRequest";
import {
  PaginationReponse,
  RequestFilter,
  StatusType,
} from "interfaces/general";
import { RootState } from "..";
import { CATEGORY_PER_PAGE } from "pages/categories";

export const getCategories = createAsyncThunk(
  "getCategories",
  async (
    {
      page = 1,
      getAll,
      status,
      user,
      customCountryId,
    }: { page?: number; getAll?: boolean; status?: StatusType, user?: any, customCountryId?: string },
    { rejectWithValue, getState }
  ) => {
    const pageSize = CATEGORY_PER_PAGE;
    const state = getState() as RootState;
    const myBody: RequestFilter = {
      Type: "and",
      Items: [
        {
          Name: "countryId",
          Value: customCountryId ? customCountryId : state.countries.countryId,
          Type: "=",
        },
      ],
    };
    if (status) {
      myBody.Items.push({
        Name: "status",
        Value: status,
        Type: "=",
      });
    }

    try {
      const result = await HttpRequest<
        RequestFilter,
        PaginationReponse<Category>
      >({
        url: `Admin/CategoriesView/GetAll`,
        method: RequestType.POST,
        body: myBody,
        params: {
          ...(!getAll && {
            skip: (page - 1) * pageSize,
            take: pageSize,
          }),
          orderBy: "title asc",
        },
      });

      if(user.role === "SUPERADMIN") {
        return result
       }else if (user.role === "ADMIN") {
        return result
       }else if (user.role === "EDITOR") {
         const filteredCategories = result.data.filter((category) => user.managedCategories.includes(category.title));
         return {
           ...result,
            data: filteredCategories
         };
       }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCategories = createAsyncThunk(
  "updateCategories",
  async ({ category }: { category: UpdateCategory }) => {
    const res = HttpRequest<UpdateCategory, Category>({
      url: `Admin/Categories/Update`,
      method: RequestType.POST,
      body: category,
    });

    return res;
  }
);

export const createCategory = createAsyncThunk(
  "createCategory",
  async function ({ category }: { category: CreateCategory }) {
    const res = HttpRequest<CreateCategory, Category>({
      url: `Admin/Categories/Add`,
      method: RequestType.POST,
      body: category,
    });
    return res;
  }
);

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async function (categoryId: string) {
    const res = HttpRequest({
      url: `Admin/Categories/Delete?id=${categoryId}`,
      method: RequestType.POST,
    });
    return res;
  }
);