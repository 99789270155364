import {
  Box,
  Button,
  inputLabelClasses,
  Modal,
  OutlinedInputProps,
  TextField,
  Typography,
} from "@mui/material";
import { FC } from "react";
import CloseIcon from "assets/categories/CloseIcon";

type ClientDetailModalProps = {
  open: boolean;
  onClose: any;
  item?: any;
};

const ClientDetailModal: FC<ClientDetailModalProps> = ({
  open,
  onClose,
  item,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "591px",
          bgcolor: "background.paper",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          onClick={onClose}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            cursor: "pointer",
            padding: "12px 16px",
          }}
        >
          <CloseIcon />
        </Box>
        <Typography variant="paragraph" color="#000" mb="24px">
          Client
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "24px",
          }}
        >
          <TextField
            id="firstName"
            label="Name Surname"
            variant="filled"
            value={item?.name}
            // onChange={(e) => setEmail(e.target.value)}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <TextField
            id="email"
            label="E-mail"
            variant="filled"
            value={item?.email}
            // onChange={(e) => setEmail(e.target.value)}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <TextField
            id="phone"
            label="Phone Number"
            variant="filled"
            value={"+90 555 123 45 67"}
            // onChange={(e) => setEmail(e.target.value)}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
            }}
          >
            <TextField
              sx={{ flex: 1 }}
              id="startDate"
              label="Start Date"
              variant="filled"
              value={item?.registeredDate}
              // onChange={(e) => setEmail(e.target.value)}
              InputProps={
                {
                  disableUnderline: true,
                } as Partial<OutlinedInputProps>
              }
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#c2c2c2",
                  },
                },
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              id="endDate"
              label="End Date"
              variant="filled"
              value={item?.finishDate}
              // onChange={(e) => setEmail(e.target.value)}
              InputProps={
                {
                  disableUnderline: true,
                } as Partial<OutlinedInputProps>
              }
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#c2c2c2",
                  },
                },
              }}
            />
          </Box>
          <TextField
            id="region"
            label="Region"
            variant="filled"
            value={item?.region}
            // onChange={(e) => setEmail(e.target.value)}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <TextField
            id="price"
            label="Price"
            variant="filled"
            value={
              item?.status === "Teklif bekliyor"
                ? item?.status
                : `${item?.status} - Ödendi`
            }
            // onChange={(e) => setEmail(e.target.value)}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <Button variant="contained" onClick={onClose}>
            <Typography variant="buttonText">OK</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ClientDetailModal;
