import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "assets/categories/CloseIcon";
import { useTranslation } from "react-i18next";

type WarnModalProps = {
  open: boolean;
  onClose: any;
  title: string;
  description: string;
  onSubmit: any;
  onCancel?: any;
};

const WarnModal: React.FC<WarnModalProps> = ({
  open,
  onClose,
  title,
  description,
  onSubmit,
  onCancel,
}) => {

  const { t } = useTranslation(["cards"]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          height: "auto",
          bgcolor: "background.paper",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          onClick={onClose}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            cursor: "pointer",
            padding: "12px 16px",
          }}
        >
          <CloseIcon />
        </Box>
        <Typography variant="paragraph" color="#000" fontWeight={700}>
          {title}
        </Typography>
        <Typography variant="paragraph" m="16px 0px">
          {description}
        </Typography>

        <Box sx={{ display: "flex", width: "280px", gap: "16px" }}>
          <Button
            variant="outlined"
            onClick={() => {
              onSubmit();
              onClose();
            }}
            sx={{ flex: 1 }}
          >
            <Typography variant="buttonText">{t('yes')}</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onCancel?.();
              onClose();
            }}
            sx={{ flex: 1 }}
          >
            <Typography variant="buttonText">{t('cancel')}</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default WarnModal;
