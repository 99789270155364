import {
  Box,
  Button,
  FormControl,
  inputLabelClasses,
  MenuItem,
  OutlinedInputProps,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MeatballsMenuIcon from "assets/cards/MeatballsMenuIcon";
import ShareLinkIcon from "assets/notifications/ShareLinkIcon";
import CustomTable, { Column } from "components/customTable";
import Layout from "components/layout";
import { notificationsData } from "mock/notificationsData";
import usePagination from "utils/usePagination";
import { Header } from "./styles";

const Buttons = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "24px",
      }}
    >
      <Button
        sx={{
          background: "transparent",
          border: "1px solid #34C759",
          "&:hover": {
            background: "transparent",
          },
        }}
      >
        <Typography color="#34C759">Edit</Typography>
      </Button>
      <Button
        sx={{
          background: "transparent",
          border: "1px solid #ED0505",
          "&:hover": {
            background: "transparent",
          },
        }}
      >
        <Typography color="#ED0505">Delete</Typography>
      </Button>
    </Box>
  );
};

const Notifications = () => {
  const [filters, setFilters] = useState("");

  const cardsArray = new Array(20).fill({});

  let [page, setPage] = useState(1);
  const PER_PAGE = 8;

  const count = Math.ceil(cardsArray.length / PER_PAGE);
  const _DATA = usePagination(cardsArray, PER_PAGE);

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const columns: Column<any>[] = [
    {
      id: "content",
      label: "CONTENT",
      width: "60%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.content}
        </Typography>
      ),
    },
    {
      id: "date",
      label: "DATE",
      width: "10%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.date}
        </Typography>
      ),
    },
    {
      id: "view",
      label: "VIEW",
      width: "10%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.view}
        </Typography>
      ),
    },
    {
      id: "Buttons",
      label: "",
      width: "10%",
      render: (item) => <Buttons />,
    },
    {
      id: "menu",
      label: "",
      width: "10%",
      render: (item) => <MeatballsMenuIcon />,
    },
  ];

  return (
    <Layout>
      <Header>
        <Typography variant="paragraph">Filters: </Typography>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={filters}
            onChange={(e: any) => setFilters(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Users</MenuItem>
            <MenuItem value="superadmin">Super Admin</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
      </Header>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "#fff",
          borderRadius: "4px",
          padding: "24px",
          gap: "24px",
        }}
      >
        <Typography variant="paragraph" color="#000" fontSize="16px">
          Notification Create
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <TextField
            sx={{ flex: 0.6 }}
            id="newNotification"
            label="Content"
            variant="filled"
            // onChange={(e) => setEmail(e.target.value)}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <ShareLinkIcon />
          <Button>
            <Typography variant="buttonText" sx={{ ml: "32px", mr: "32px" }}>
              Save
            </Typography>
          </Button>
        </Box>
      </Box>
      <CustomTable
        title="Notifications"
        columns={columns}
        data={notificationsData}
      />
    </Layout>
  );
};

export default Notifications;
