import { createSlice } from "@reduxjs/toolkit";
import { PaginationReponse } from "interfaces/general";
import { User } from "interfaces/user";
import { getUsers, updateUser, deleteUser } from "./api";

export interface UsersState {
  users: PaginationReponse<User>;
  loading: boolean;
  error: any;
}

const initialState: UsersState = {
  users: { data: [], length: 0, skip: 0, take: 0 },
  loading: false,
  error: "",
};

export const usersSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
      state.loading = false;
    });

    builder.addCase(getUsers.rejected, (state) => {
      state.loading = false;
      state.error = "Error fetching cards data!";
    });

    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const index = state.users.data.findIndex(
          (user) => user.id === action.payload.id
        );
        state.users.data[index] = action.payload;
        state.loading = false;
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      });

    builder
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(deleteUser.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      });

  },
});

export default usersSlice.reducer;
