import {
  alpha,
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CustomTable, { Column } from "components/customTable";
import Layout from "components/layout";
import SwitchComp from "components/switchComp";
import {
  User,
  UserLevel,
  UserLevelText,
  UserRole,
  UserStatus,
} from "interfaces/user";
import { useAppDispatch, useAppSelector } from "services";
import { getUsers, updateUser } from "services/usersSlice/api";
import { numberFormat } from "utils/helperFunctions";
import usePagination from "utils/usePagination";
import { Header, Footer } from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import { CustomMenu } from "./components/userPopup";

const PER_PAGE = 8;

const Users = () => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [userRole, setUserRole] = useState<UserRole>(UserRole.ALLUSERS);
  const [userLevel, setUserLevel] = useState<UserLevel>(UserLevel.ALLLEVEL);
  const [search, setSearch] = useState("");
  const [userStatus, setUserStatus] = useState<UserStatus>(
    UserStatus.ALLSTATUS
  );

  const { users } = useAppSelector((state) => state.users);
  /* const handleData = (id: string) => {
    const index = data.findIndex((item: any) => item.id === id);
    const obj = data[index];
    data.splice(index, 1, { ...obj, status: !obj.status });
    setData([...data]);
  }; */
  const { cities } = useAppSelector((state) => state.cities);
  const { countryId, countries } = useAppSelector((state) => state.countries);

  useEffect(() => {
    dispatch(
      getUsers({
        page,
        pageSize: PER_PAGE,
      })
    );
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(
      getUsers({
        page: 1,
        pageSize: PER_PAGE,
        role: userRole === "" ? undefined : userRole,
        level: userLevel === -1 ? undefined : userLevel,
        search,
        status: userStatus === "" ? undefined : userStatus,
      })
    );
    // eslint-disable-next-line
  }, [countryId, userRole, userLevel, userStatus]);

  useEffect(() => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => {
      dispatch(
        getUsers({
          page,
          search,
        })
      );
    }, 500);
    // eslint-disable-next-line
  }, [search]);

  const ref = useRef<any>();

  const count = Math.ceil(users.length / PER_PAGE);
  const _DATA = usePagination<User>(users.data, PER_PAGE);

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const columns: Column<User>[] = [
    {
      id: "name",
      label: "NAME",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.firstName
            ? `${item.firstName} ${item.lastName} `
            : "Anonymous User"}
        </Typography>
      ),
    },
    {
      id: "phone",
      label: "PHONE",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.phone
            ? `+${item.phoneCountryCode} ${item.phone.replace(
                /(?<=.{3}).(?=.{2})/g,
                "*"
              )}`
            : "N/A"}
        </Typography>
      ),
    },
    {
      id: "email",
      label: "E-MAIL",
      width: "20%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.email ? item.email.replace(/(?<=.{2}).(?=.{10})/g, "*") : "N/A"}
        </Typography>
      ),
    },
    {
      id: "registeredDate",
      label: "REGISTERED DATE",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {new Date(item.registeredDate).toLocaleDateString()}
        </Typography>
      ),
    },
    {
      id: "userLevel",
      label: "USER TYPE",
      width: "10%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {UserLevelText[item.level]}
        </Typography>
      ),
    },
    {
      id: "region",
      label: "REGION",
      width: "10%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.cityId
            ? cities.find((city) => city.id === item.cityId)?.name
            : "N/A"}
        </Typography>
      ),
    },
    {
      id: "svaypPoints",
      label: "SVAYP POINTS",
      width: "10%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.points}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "STATUS",
      width: "10%",
      render: (item) => (
        <SwitchComp
          checked={item.status === "ACTIVE"}
          setChecked={() => {
            dispatch(
              updateUser({
                user: {
                  ...item,
                  status: item.status === "ACTIVE" ? "PASSIVE" : "ACTIVE",
                },
              })
            );
          }}
        />
      ),
    },
    {
      id: "menu",
      label: "",
      width: "10%",
      render: (item) => <CustomMenu item={item} page={page} />,
    },
  ];

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(TextField)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

  return (
    <Layout>
      <Header>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            minHeight: "120px",
            height: "120px",
            minWidth: "900px",
          }}
        >
          <Typography variant="paragraph">Filters: </Typography>
          <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={userRole}
              onChange={(e: any) => {
                setUserRole(e.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "36px", fontSize: "14px" }}
            >
              <MenuItem value={""}>All Users</MenuItem>
              <MenuItem value={UserRole.ANON}>Anon</MenuItem>
              <MenuItem value={UserRole.EDITOR}>Editor</MenuItem>
              <MenuItem value={UserRole.USER}>User</MenuItem>
              <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
              <MenuItem value={UserRole.SUPERADMIN}>Super Admin</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={userLevel}
              onChange={(e: any) => setUserLevel(e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "36px", fontSize: "14px" }}
            >
              <MenuItem value={UserLevel.ALLLEVEL}>All User Type</MenuItem>
              <MenuItem value={UserLevel.VERIFIED}>Kayıtlı Profil</MenuItem>
              <MenuItem value={UserLevel.ADVANCED}>Gelişmiş Profil</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={userStatus}
              onChange={(e: any) => setUserStatus(e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "36px", fontSize: "14px" }}
            >
              <MenuItem value={""}>All Status</MenuItem>
              <MenuItem value={UserStatus.ACTIVE}>Active</MenuItem>
              <MenuItem value={UserStatus.PASSIVE}>Passive</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon color="disabled" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={"Search"}
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Search>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "32px",
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
      </Header>
      <Typography variant="body1">{`${
        countries?.find((country) => country.id === countryId)?.name
      } bölgesinde kayıtlı kullanıcı sayısı "${numberFormat(
        users.length
      )}"`}</Typography>
      <CustomTable
        title="Registered Users"
        columns={columns}
        data={_DATA.currentData()}
        noDataMessage={"No data found"}
      />

      <Footer>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "32px",
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
      </Footer>
    </Layout>
  );
};

export default Users;
