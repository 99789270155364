import {
  Box,
  Button,
  Checkbox,
  inputLabelClasses,
  OutlinedInputProps,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LoginBgTR from "assets/login/loginBgTR.png";
import LoginBgEN from "assets/login/loginBgEN.png";
import LoginBgDE from "assets/login/loginBgDE.png";
import LoginBgRU from "assets/login/loginBgRU.png";
import LoginBgES from "assets/login/loginBgES.png";
import SvaypLogoBig from "assets/login/svayp-logo-big.png";

import { LoginWrapper, LoginCard, BottomTextWrapper } from "./styles";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "services";
import { login } from "services/authSlice";
import { useNavigate } from "react-router-dom";

import Checked from "assets/icons/Checked";
import Unchecked from "assets/icons/Unchecked";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getCurrentUser } from "services/authSlice/api";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t, i18n } = useTranslation("loginPage");

  const changeLanguage = () => {
    if (i18n.language === "us") {
      i18n.changeLanguage("tr");
      localStorage.setItem("i18nextLng", "tr");
    } else {
      i18n.changeLanguage("us");
      localStorage.setItem("i18nextLng", "us");
    }
  };

  const sliderImages = [LoginBgTR, LoginBgEN, LoginBgDE, LoginBgRU, LoginBgES];

  const handleLogin = () => {
    if (!email && !password) {
      return toast.error<never>(t("login-error"));
    }
    if (!email) {
      return toast.error<never>(t("login-email-error"));
    }
    if (!password) {
      return toast.error<never>(t("login-password-error"));
    }

    const loginPromise = new Promise(async (resolve, reject) => {
      try {
        const res = await dispatch(login({ email, password }));

        if (res.payload.accessToken) {
          localStorage.setItem(
            "token",
            JSON.stringify(res.payload.accessToken)
          );
          dispatch(getCurrentUser());
          navigate("/");
          resolve(t("login-success"));
        } else {
          reject(t("login-failed"));
        }
      } catch (error) {
        reject(t("login-failed"));
      }
    });

    toast.promise(loginPromise, {
      pending: t("login-pending"),
      success: t("login-success"),
      error: t("login-failed"),
    });
  };

  const settings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    // swipe: false,
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Box
      sx={{
        width: "100wv",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: "65%",
        }}
      >
        <Slider {...settings}>
          {sliderImages.map((item: any, index: number) => (
            <Box
              key={`login-bg-${index}`}
              sx={{
                width: "100%",
                height: "100vh",
                objectFit: "cover",
              }}
              component="img"
              src={item}
            />
          ))}
        </Slider>
      </Box>
      <LoginWrapper>
        <LoginCard>
          <Box
            sx={{
              width: "128px",
              height: "32px",
            }}
            component="img"
            src={SvaypLogoBig}
          />
          <Typography
            variant="title1"
            style={{ display: "inline-block", whiteSpace: "pre-line" }}
          >
            {t("title")}
          </Typography>
          <Typography variant="subTitle1">{t("subtitle")}</Typography>
          <TextField
            id="e-mail"
            label={t("email")}
            variant="filled"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyPress}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <TextField
            id="password"
            label={t("password")}
            type="password"
            variant="filled"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#c2c2c2",
                },
              },
            }}
          />
          <Box>
            <Checkbox icon={<Unchecked />} checkedIcon={<Checked />} />
            <Typography variant="paragraph">{t("remember")}</Typography>
          </Box>
          <Button variant="contained" onClick={handleLogin}>
            <Typography variant="buttonText">{t("signin")}</Typography>
          </Button>
          <Typography
            variant="clickable"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/forgotPassword");
            }}
          >
            {t("forgotpassword")}
          </Typography>
        </LoginCard>
        <BottomTextWrapper>
          <Typography variant="subContent">{t("rights")}</Typography>
          <Typography
            variant="subContent"
            onClick={changeLanguage}
            sx={{
              border: "0.5px solid #c2c2c2",
              borderRadius: "4px",
              padding: "2px 3px",
              cursor: "pointer",
            }}
          >
            {i18n.language === "us" ? "EN" : "TR"}
          </Typography>
        </BottomTextWrapper>
      </LoginWrapper>
    </Box>
  );
};

export default Login;
