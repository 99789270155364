import React from "react";
import { Typography, Box } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";

import LeftArrowIcon from "assets/categories/LeftArrowIcon";
import RightArrowIcon from "assets/categories/RightArrowIcon";
import UpArrowIcon from "assets/categories/UpArrowIcon";
import { useAppSelector } from "services";

const TotalVoteStats = ({ selectedData }: any) => {
  const cards = useAppSelector((state) => state.analytics.cards);

  const selectedCard = cards.find(
    (card) => card.cardId === selectedData?.cardId
  );

  const anonLeftStats = selectedCard?.dynamics[0].optionleftstat;
  const anonRightStat = selectedCard?.dynamics[0].optionrightstat;
  const anonUptStat = selectedCard?.dynamics[0].optiontopstat;

  const confirmedLeftStats = selectedCard?.dynamics[1].optionleftstat;
  const confirmedRightStats = selectedCard?.dynamics[1].optionrightstat;
  const confirmedUpStats = selectedCard?.dynamics[1].optiontopstat;

  const enhancedLeftStats = selectedCard?.dynamics[2].optionleftstat;
  const enhancedRightStats = selectedCard?.dynamics[2].optionrightstat;
  const enhancedUpStats = selectedCard?.dynamics[2].optiontopstat;

  function buildDataArray(
    optionLeftStat: any,
    optionRightStat: any,
    optionTopStat: any
  ) {
    return [
      {
        id: "anon",
        label: "Anonim",
        value: optionLeftStat,
        color: "#21252D",
      },
      {
        id: "confirmed",
        label: "Onaylı",
        value: optionRightStat,
        color: "#34C759",
      },
      {
        id: "enhanced",
        label: "Gelişmiş",
        value: optionTopStat,
        color: "#0076FF",
      },
    ].filter((item: any) => item.value > 0);
  }

  const LeftData = buildDataArray(
    anonLeftStats,
    confirmedLeftStats,
    enhancedLeftStats
  );
  const RightData = buildDataArray(
    anonRightStat,
    confirmedRightStats,
    enhancedRightStats
  );
  const UpData = buildDataArray(anonUptStat, confirmedUpStats, enhancedUpStats);

  const noData = [
    {
      id: "noData",
      label: "NoData",
      value: 1,
      color: "#dcdcdc",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "32px",
        height: "100%",
      }}
    >
      <Box bgcolor={"white"} sx={{ width: "100%" }}>
        <Box
          bgcolor={"white"}
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              py={2}
              borderBottom={0.5}
              borderColor={"#C2C2C2"}
              sx={{ width: "100%" }}
            >
              <RightArrowIcon color="black" />
              <Typography marginLeft={1}>OLUMLU</Typography>
            </Box>
            <Box
              p={1}
              sx={{
                width: "99%",
                display: "flex",
                justifyContent: "center",
                height: "270px",
              }}
            >
              {RightData.length > 0 ? (
                <ResponsivePie
                  data={RightData}
                  margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                  innerRadius={0.05}
                  padAngle={2}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  arcLabelsTextColor="#ffffff"
                  colors={{ datum: "data.color" }}
                />
              ) : (
                <ResponsivePie
                  data={noData}
                  arcLabelsTextColor="#ffffff"
                  margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                  innerRadius={0.5}
                  padAngle={1.3}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  arcLinkLabelsTextColor="#333333"
                  colors={{ datum: "data.color" }}
                />
              )}
            </Box>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              py={2}
              borderBottom={0.5}
              borderColor={"#C2C2C2"}
              sx={{ width: "100%" }}
            >
              <LeftArrowIcon color="black" />
              <Typography marginLeft={1}>OLUMSUZ</Typography>
            </Box>
            <Box
              p={1}
              sx={{
                width: "99%",
                display: "flex",
                justifyContent: "center",
                height: "270px",
              }}
            >
              {LeftData.length > 0 ? (
                <ResponsivePie
                  data={LeftData}
                  margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                  innerRadius={0.05}
                  padAngle={2}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  arcLabelsTextColor="#ffffff"
                  colors={{ datum: "data.color" }}
                />
              ) : (
                <ResponsivePie
                  data={noData}
                  arcLabelsTextColor="#ffffff"
                  margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                  innerRadius={0.5}
                  padAngle={1.3}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  arcLinkLabelsTextColor="#333333"
                  colors={{ datum: "data.color" }}
                />
              )}
            </Box>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              py={2}
              borderBottom={0.5}
              borderColor={"#C2C2C2"}
              sx={{ width: "100%" }}
            >
              <UpArrowIcon color="black" />
              <Typography marginLeft={1}>KARARSIZ</Typography>
            </Box>
            <Box
              p={1}
              sx={{
                width: "99%",
                display: "flex",
                justifyContent: "center",
                height: "270px",
              }}
            >
              {UpData.length > 0 ? (
                <ResponsivePie
                  data={UpData}
                  margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                  innerRadius={0.05}
                  padAngle={2}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  arcLabelsTextColor="#ffffff"
                  colors={{ datum: "data.color" }}
                />
              ) : (
                <ResponsivePie
                  data={noData}
                  arcLabelsTextColor="#ffffff"
                  margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                  innerRadius={0.5}
                  padAngle={1.3}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  arcLinkLabelsTextColor="#333333"
                  colors={{ datum: "data.color" }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
            marginBottom: "16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div
              style={{
                width: "22px",
                height: "22px",
                backgroundColor: "#21252d",
                borderRadius: "50%",
              }}
            ></div>
            <Typography>Anonim</Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div
              style={{
                width: "22px",
                height: "22px",
                backgroundColor: "#3bc759",
                borderRadius: "50%",
              }}
            ></div>
            <Typography>Onaylu</Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div
              style={{
                width: "22px",
                height: "22px",
                backgroundColor: "#1474f9",
                borderRadius: "50%",
              }}
            ></div>
            <Typography>Gelişmiş</Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default TotalVoteStats;
