import { useEffect, useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "services";
import { setDrawerOpen } from "services/layoutSlice";
import {
  Divider,
  FormControl,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { setToken } from "services/authSlice";
import SvaypLogoLight from "assets/layout/SvaypLogoLight";
import DashboardIcon from "assets/layout/DashboardIcon";
import DrawerListItem from "./DrawerListItem";
import CardsIcon from "assets/layout/CardsIcon";
import SvaypLogoSmall from "assets/layout/SvaypLogoSmall";
import CategoriesIcon from "assets/layout/CategoriesIcon";
import ArchivesIcon from "assets/layout/ArchivesIcon";
import ModeratorsIcon from "assets/layout/ModeratorsIcon";
import AnalyticsIcon from "assets/layout/AnalyticsIcon";
import UsersIcon from "assets/layout/UsersIcon";
import ClientsIcon from "assets/layout/ClientsIcon";
import NotificationsIcon from "assets/layout/NotificationsIcon";
import ActivityLogsIcon from "assets/layout/ActivityLogsIcon";
import {
  BottomTextWrapper,
  LayoutWrapper,
  Breadcrumbs,
  VersionBox,
} from "./styles";
import UserAccountIcon from "assets/layout/UserAccountIcon";
import CollapseIcon from "assets/layout/CollapseIcon";
import ExpandIcon from "assets/layout/ExpandIcon";
import { useTranslation } from "react-i18next";
import { useAuth } from "utils/useAuth";
import { setCategoryId } from "services/categoriesSlice";
import { useLocalStorage } from "utils/useLocalStorage";
import { getLanguages, setLanguageId } from "services/languagesSlice";
import { getOptions } from "services/optionsSlice";
import { getCities } from "services/citiesSlice/api";
import { getCountries } from "services/countries/api";
import { setCountryId } from "services/countries";
import renderSkeleton from "utils/SkeletonUtils";
import CountriesIcon from "assets/layout/CountriesIcon";
import { latestVersion } from "mock/changeLogs";
import {
  AccountBox,
  Feedback,
  HelpCenter,
  Logout,
  Settings,
} from "@mui/icons-material";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 8.8px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const settings = ["Profile", "Settings", "Feedback", "Help", "Logout"];

export default function Layout({ children }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["layout"]);
  const { token } = useAuth() as any;
  const { drawerOpen } = useAppSelector((state) => state.layout);
  const { countries, countryId } = useAppSelector((state) => state.countries);
  const { languages, languageId } = useAppSelector((state) => state.languages);
  const { cards } = useAppSelector((state) => state.cards);
  const user = useAppSelector((state) => state.auth.user);
  const [drawerLocal, setDrawerLocal] = useLocalStorage("drawerLocal", null);
  const [countryIdLocal, setCountryIdLocal] = useLocalStorage(
    "countryIdLocal",
    null
  );
  const [languageIdLocal, setLanguageIdLocal] = useLocalStorage(
    "languageIdLocal",
    null
  );
  const quickActions =
    user.role === "SUPERADMIN"
      ? [
          "Quick Actions",
          "Add Country",
          "Add Card",
          "Add Category",
          "Add Moderator",
        ]
      : ["Quick Actions", "Add Card"];

  const [selectedQuickAction, setSelectedQuickAction] =
    useState("Quick Actions");

  useEffect(() => {
    dispatch(getCountries({ user }));

    dispatch(getLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /*  useEffect(() => {
    if (countryId && languages.length) {
      const firstLanguage = languages[0]?.filter((language: any) => {
        return language.countryId === countryId;
      });
      setLanguageId(firstLanguage);
      setLanguageIdLocal(firstLanguage);
    }
  }, []); */

  useEffect(() => {
    if (countryIdLocal) {
      dispatch(setCountryId(countryIdLocal));
      /*  dispatch(getCategories({})); */
      dispatch(getCities({ countryId: countryIdLocal }));
    } else if (countryId) {
      setCountryIdLocal(countryId);
      /* dispatch(getCategories({})); */
    }

    if (languageIdLocal) {
      dispatch(setLanguageId(languageIdLocal));
      dispatch(getOptions({ token, languageId: languageIdLocal }));
    } else if (languageId) {
      setLanguageIdLocal(languageId);
      dispatch(getOptions({ token, languageId: languageId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (drawerLocal) {
      dispatch(setDrawerOpen(drawerLocal.value));
    }
  }, [dispatch, drawerLocal]);

  useEffect(() => {
    if (selectedQuickAction === "Add Card") {
      navigate("/cards/addCard");
    }
    if (selectedQuickAction === "Add Category") {
      navigate("/categories", {
        state: {
          addCategory: true,
        },
      });
    }
    if (selectedQuickAction === "Add Country") {
      navigate("/countries", {
        state: {
          addCountry: true,
        },
      });
    }
    if (selectedQuickAction === "Add Moderator") {
      navigate("/moderators", {
        state: {
          addModerator: true,
        },
      });
    }
    setSelectedQuickAction("Quick Actions");
  }, [selectedQuickAction, navigate]);

  const changeLanguage = () => {
    if (i18n.language === "us") {
      i18n.changeLanguage("tr");
      localStorage.setItem("i18nextLng", "tr");
    } else {
      i18n.changeLanguage("us");
      localStorage.setItem("i18nextLng", "us");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("i18nextLng") === "tr") {
      i18n.changeLanguage("tr");
    } else {
      i18n.changeLanguage("us");
    }
  }, [i18n]);

  useEffect(() => {
    switch (countryId) {
      case "f88c8e76-6e6a-4859-ac8c-078b4aef0a05":
        if (!localStorage.getItem("i18nextLng")) {
          i18n.changeLanguage("tr");
        }
        break;
      case "5961be98-9457-4b0f-8724-1d9902244482":
        if (!localStorage.getItem("i18nextLng")) {
          i18n.changeLanguage("us");
        }
        break;
      case "5a3ea9513-89f4-4fe0-9ffa-89a9cdcdf084":
        if (!localStorage.getItem("i18nextLng")) {
          i18n.changeLanguage("us");
        }
        break;
      case "b2078873-6f22-4d57-b0ff-9749266e32f8":
        if (!localStorage.getItem("i18nextLng")) {
          i18n.changeLanguage("fr");
        }
        break;
      default:
        if (!localStorage.getItem("i18nextLng")) {
          i18n.changeLanguage("us");
        }
        break;
    }
  }, [countryId, i18n]);

  const handleDrawerOpen = () => {
    dispatch(setDrawerOpen(true));
    setDrawerLocal({ value: true });
  };

  const handleDrawerClose = () => {
    dispatch(setDrawerOpen(false));
    setDrawerLocal({ value: false });
  };

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting: string) => {
    setAnchorElUser(null);
    if (setting === "Logout") {
      localStorage.removeItem("token");
      dispatch(setToken(""));
      navigate("/login");
    }
  };

  const handleCountryFilter = (e: any) => {
    const newCountryId = e.target.value;
    dispatch(setCountryId(newCountryId));
    const firstLanguage = languages?.filter((language: any) => {
      return language.countryId === newCountryId;
    });
    if (firstLanguage) {
      setLanguageId(firstLanguage[0].languageId);
      setLanguageIdLocal(firstLanguage[0].languageId);
      dispatch(setLanguageId(firstLanguage[0].languageId));
    }
    setCountryIdLocal(newCountryId);

    /* dispatch(getCategories({})); */
    dispatch(getCities({ countryId: newCountryId }));
    dispatch(setCategoryId(""));
  };

  const handleLanguageFilter = (e: any) => {
    const newLanguageId = e.target.value;
    setLanguageIdLocal(newLanguageId);
    dispatch(setLanguageId(newLanguageId));
    dispatch(getOptions({ token, languageId: newLanguageId }));
  };

  // // Geçici olarak zoom oranını 80% olarak ayarladım. Zoom oranı 100% olunca content tam görünmüyor.
  // useEffect(() => {
  //   if (window.screen.width < 2500) {
  //     document.body.style.zoom = "85%";
  //   } else {
  //     document.body.style.zoom = "100%";
  //   }
  // }, []);

  // collapse Menu

  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setCurrentWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (currentWidth < 1280) {
      dispatch(setDrawerOpen(false));
    } else {
      dispatch(setDrawerOpen(true));
    }

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, [window.innerWidth, dispatch]);

  const [cardsCount, setCardsCount] = useState(0);

  const formatCount = (count: any) => {
    if (count < 999) {
      return count.toString();
    } else if (count < 999999) {
      return (count / 1000).toFixed(1) + "K";
    } else if (count < 999999999) {
      return (count / 1000000).toFixed(1) + "M";
    } else {
      return (count / 1000000000).toFixed(1) + "B";
    }
  };

  const getTotalCardsCount = () => {
    fetch(`https://api.svayp.com/Admin/Cards/GetDynamic`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        Where: {
          NAME: "countryId",
          VALUE: countryId,
          TYPE: "=",
        },
        Take: 1,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCardsCount(formatCount(data.length));
      });
  };

  useEffect(() => {
    getTotalCardsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, countryId]);

  const breadcrumbs = () => {
    switch (location.pathname) {
      case "/cards":
        return ["Cards"];
      case "/cards/addCard":
        return ["Cards", "Add Card"];
      case "/cards/share":
        return ["Cards", "Share Card"];
      case "/categories":
        return ["Categories"];
      case "/countries":
        return ["Countries"];
      case "/archives":
        return ["Archives"];
      case "/moderators":
        return ["Moderators"];
      case "/analytics":
        return ["Analytics"];
      case "/users":
        return ["Users"];
      case "/clients":
        return ["Clients"];
      case "/notifications":
        return ["Notifications"];
      case "/activityLogs":
        return ["Activity Logs"];
      case "/changeLogs":
        return ["Change Logs"];
      default:
        return [];
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={drawerOpen}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "32px",
          background: "white",
          height: "64px",
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: drawerOpen ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px",
              height: "64px",
              width: "64px",
              background: "#272B35",
            }}
            onClick={handleDrawerOpen}
          >
            <SvaypLogoSmall />
          </Box>
          {countries?.length !== 0 ? (
            <Box sx={{ margin: "0 16px" }}>
              <FormControl sx={{ width: "120px" }}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={countryId}
                  onChange={handleCountryFilter}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: "36px", fontSize: "14px" }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {countries?.map((country: any) => {
                    if (!country.active === false) return null;
                    return (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : (
            renderSkeleton({
              width: 120,
              height: 36,
              style: { margin: "0 16px" },
            })
          )}
          {countries?.length !== 0 ? (
            <Box sx={{ margin: "0 16px" }}>
              <FormControl sx={{ width: "120px" }}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={languageId}
                  onChange={handleLanguageFilter}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: "36px", fontSize: "14px" }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {languages
                    ?.filter((language) => {
                      return language.countryId === countryId;
                    })
                    .map((language: any) => {
                      /* if (!language.totalCount) return; */
                      return (
                        <MenuItem
                          key={language.languageId}
                          value={language.languageId}
                        >
                          {language.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          ) : (
            renderSkeleton({
              width: 120,
              height: 36,
              style: { margin: "0 16px" },
            })
          )}
        </Box>
        <Box sx={{ flexGrow: 0, display: "flex" }}>
          <Box sx={{ margin: "0 16px" }}>
            <FormControl sx={{ width: "150px" }}>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedQuickAction}
                onChange={(e) => setSelectedQuickAction(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ height: "36px", fontSize: "14px" }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                {quickActions.map((item, idx) => (
                  <MenuItem
                    style={{ display: idx === 0 ? "none" : "block" }}
                    key={item}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Tooltip title="Open settings">
            <IconButton
              onClick={handleOpenUserMenu}
              TouchRippleProps={{ style: { background: "transparent" } }}
            >
              <Typography
                variant="paragraph"
                sx={{ marginRight: "16px", textTransform: "capitalize" }}
              >
                {user.role
                  ? user.firstName + " " + user.lastName
                  : renderSkeleton({ width: 120, height: 10 })}
              </Typography>
              <UserAccountIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting, index) => [
              setting === "Logout" && (
                <Divider key={`divider-${index}`} sx={{ my: 0.5 }} />
              ),
              <MenuItem
                key={`menu-item-${index}`}
                onClick={() => handleCloseUserMenu(setting)}
              >
                <ListItemIcon>
                  {setting === "Profile" && <AccountBox fontSize="small" />}
                  {setting === "Settings" && <Settings fontSize="small" />}
                  {setting === "Feedback" && <Feedback fontSize="small" />}
                  {setting === "Help" && <HelpCenter fontSize="small" />}
                  {setting === "Logout" && <Logout fontSize="small" />}
                </ListItemIcon>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>,
            ])}
          </Menu>
        </Box>
      </AppBar>

      <Drawer variant="permanent" open={drawerOpen} className="hidde-scrollbar">
        <DrawerHeader
          sx={{
            background: "#272B35",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <SvaypLogoLight onClick={handleDrawerClose} />
          <Link to="/changeLogs">
            <Tooltip title="Change Log">
              <VersionBox>V {latestVersion}</VersionBox>
            </Tooltip>
          </Link>
        </DrawerHeader>
        <List
          sx={{
            background: "#2B303B",
            flex: 1,
            overflowY: "hidden",
          }}
        >
          <DrawerListItem
            title={t("dashboard")}
            subtitle={"12 " + t("new-updates")}
            route="/"
            drawerOpen={drawerOpen}
            icon={<DashboardIcon primarycolor={pathname === "/" ? 1 : 0} />}
          />
          <DrawerListItem
            title={t("cards")}
            subtitle={`${cardsCount} ${t("new-cards")}`}
            route="/cards"
            drawerOpen={drawerOpen}
            icon={
              <CardsIcon primarycolor={pathname.includes("/cards") ? 1 : 0} />
            }
          />

          {user && Object.keys(user).length !== 0
            ? user.role === "SUPERADMIN" && (
                <DrawerListItem
                  title={t("categories")}
                  route="/categories"
                  drawerOpen={drawerOpen}
                  icon={
                    <CategoriesIcon
                      primarycolor={pathname === "/categories" ? 1 : 0}
                    />
                  }
                />
              )
            : renderSkeleton({
                style: { padding: 5, marginTop: 8 },
                height: "50px",
                baseColor: "#21252d",
                highlightColor: "#292e39",
              })}

          {user && Object.keys(user).length !== 0
            ? user.role === "SUPERADMIN" && (
                <DrawerListItem
                  title={t("countries")}
                  route="/countries"
                  drawerOpen={drawerOpen}
                  icon={
                    <CountriesIcon
                      primarycolor={pathname === "/countries" ? 1 : 0}
                    />
                  }
                />
              )
            : renderSkeleton({
                style: { padding: 5, marginTop: 6 },
                height: "50px",
                baseColor: "#21252d",
                highlightColor: "#292e39",
              })}
          {user && Object.keys(user).length !== 0
            ? user.role === "SUPERADMIN" && (
                <DrawerListItem
                  title={t("archives")}
                  route="/archives"
                  drawerOpen={drawerOpen}
                  icon={
                    <ArchivesIcon
                      primarycolor={pathname === "/archives" ? 1 : 0}
                    />
                  }
                />
              )
            : renderSkeleton({
                style: { padding: 5, marginTop: 6 },
                height: "50px",
                baseColor: "#21252d",
                highlightColor: "#292e39",
              })}
          {user && Object.keys(user).length !== 0
            ? user.role === "SUPERADMIN" && (
                <DrawerListItem
                  title={t("moderators")}
                  route="/moderators"
                  drawerOpen={drawerOpen}
                  icon={
                    <ModeratorsIcon
                      primarycolor={pathname === "/moderators" ? 1 : 0}
                    />
                  }
                />
              )
            : renderSkeleton({
                style: { padding: 5, marginTop: 6 },
                height: "50px",
                baseColor: "#21252d",
                highlightColor: "#292e39",
              })}
          {user && Object.keys(user).length !== 0
            ? user.role === "SUPERADMIN" && (
                <DrawerListItem
                  title={t("analytics")}
                  route="/analytics"
                  drawerOpen={drawerOpen}
                  icon={
                    <AnalyticsIcon
                      primarycolor={pathname === "/analytics" ? 1 : 0}
                    />
                  }
                />
              )
            : renderSkeleton({
                style: { padding: 5, marginTop: 8 },
                height: "50px",
                baseColor: "#21252d",
                highlightColor: "#292e39",
              })}
          {user && Object.keys(user).length !== 0
            ? user.role === "SUPERADMIN" && (
                <DrawerListItem
                  title={t("users")}
                  route="/users"
                  drawerOpen={drawerOpen}
                  icon={
                    <UsersIcon primarycolor={pathname === "/users" ? 1 : 0} />
                  }
                />
              )
            : renderSkeleton({
                style: { padding: 5, marginTop: 6 },
                height: "50px",
                baseColor: "#21252d",
                highlightColor: "#292e39",
              })}
          {user && Object.keys(user).length !== 0
            ? user.role === "SUPERADMIN" && (
                <DrawerListItem
                  title={t("clients")}
                  route="/clients"
                  drawerOpen={drawerOpen}
                  icon={
                    <ClientsIcon
                      primarycolor={pathname === "/clients" ? 1 : 0}
                    />
                  }
                />
              )
            : renderSkeleton({
                style: { padding: 5, marginTop: 6 },
                height: "50px",
                baseColor: "#21252d",
                highlightColor: "#292e39",
              })}
          {user && Object.keys(user).length !== 0
            ? user.role === "SUPERADMIN" && (
                <DrawerListItem
                  title={t("notifications")}
                  route="/notifications"
                  drawerOpen={drawerOpen}
                  icon={
                    <NotificationsIcon
                      primarycolor={pathname === "/notifications" ? 1 : 0}
                    />
                  }
                />
              )
            : renderSkeleton({
                style: { padding: 5, marginTop: 6 },
                height: "50px",
                baseColor: "#21252d",
                highlightColor: "#292e39",
              })}
          {user && Object.keys(user).length !== 0
            ? user.role === "SUPERADMIN" && (
                <DrawerListItem
                  title={t("activity-logs")}
                  route="/activityLogs"
                  drawerOpen={drawerOpen}
                  icon={
                    <ActivityLogsIcon
                      primarycolor={pathname === "/activityLogs" ? 1 : 0}
                    />
                  }
                />
              )
            : renderSkeleton({
                style: { padding: 5, marginTop: 6 },
                height: "50px",
                baseColor: "#21252d",
                highlightColor: "#292e39",
              })}
          <DrawerListItem
            title={t("collapse-menu")}
            route={pathname}
            setDrawerOpen={() => dispatch(setDrawerOpen(!drawerOpen))}
            drawerOpen={drawerOpen}
            icon={
              drawerOpen ? (
                <CollapseIcon primarycolor={0} />
              ) : (
                <ExpandIcon primarycolor={0} />
              )
            }
          />
          <Typography
            variant="subContent"
            onClick={changeLanguage}
            sx={{
              position: "absolute",
              bottom: "16px",
              left: "16px",
              color: "white",
              border: "0.5px solid #c2c2c2",
              borderRadius: "4px",
              padding: "2px 3px",
              cursor: "pointer",
            }}
          >
            {i18n.language === "us" ? "EN" : "TR"}
          </Typography>
        </List>
      </Drawer>

      <LayoutWrapper>
        <DrawerHeader />
        <Breadcrumbs>
          <Link to="/">
            {t("dashboard")} {location.pathname !== "/" && "> "}
          </Link>
          {breadcrumbs()?.map((item, index) => (
            <Typography paddingLeft={1} key={index}>
              {item} {index !== breadcrumbs().length - 1 && ">"}
            </Typography>
          ))}
        </Breadcrumbs>
        <div
          style={{
            marginBottom: 80,
          }}
        >
          {children}
        </div>
        <BottomTextWrapper>
          <Typography variant="subContent">{t("rights")}</Typography>
        </BottomTextWrapper>
      </LayoutWrapper>
    </Box>
  );
}
