import {
  Box,
  FormControl,
  InputLabel,
  inputLabelClasses,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  OutlinedInputProps,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { FC, FormEvent, useEffect, useState } from "react";
import CloseIcon from "assets/categories/CloseIcon";
import { AddModeratorDTO, User, UserRole } from "interfaces/user";
import { useAppDispatch, useAppSelector } from "services";
import {
  createModerator,
  updateModerator,
} from "services/moderatorsSlice/api";
import { toast } from "react-toastify";
import { Checkbox } from "@mui/material";
import { getCountries } from "services/countries";
import { getCategories } from "services/categoriesSlice";
import { StatusType } from "interfaces/general";
import renderSkeleton from "utils/SkeletonUtils";
import { LoadingButton } from "@mui/lab";

type AddModeratorModalProps = {
  open: boolean;
  onClose: any;
  moderator?: User;
};

const AddModeratorModal: FC<AddModeratorModalProps> = ({
  open,
  onClose,
  moderator,
}) => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector((state) => state.countries);
  const { countryId } = useAppSelector((state) => state.countries);
  const moderatorLoading = useAppSelector((state) => state.moderators.loading);
  const [role, setRole] = useState<UserRole>(
    moderator?.role || UserRole.ALLUSERS
  );
  const [firstName, setFirstName] = useState<string>(
    moderator?.firstName || ""
  );
  const [lastName, setLastName] = useState<string>(moderator?.lastName || "");
  const [email, setEmail] = useState<string>(moderator?.email || "");
  const [password, setPassword] = useState<string>(moderator?.password || "");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [editorCountry, setEditorCountry] = useState<string>(
    moderator?.managedCountries[0] || ""
  );
  const { categories, loading } = useAppSelector((state) => state.categories);
  const [managedCategories, setManagedCategories] = useState<string[]>(
    moderator?.managedCategories || []
  );
  const [selectedEditorCountryId, setSelectedEditorCountryId] =
    useState<string>(countryId);
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(
      getCategories({
        user,
        status: StatusType.ACTIVE,
        getAll: true,
        customCountryId: selectedEditorCountryId,
      })
    );
    if (!moderator) {
      dispatch(getCountries({ user }));
      dispatch(
        getCategories({
          user,
          status: StatusType.ACTIVE,
          getAll: true,
          customCountryId: selectedEditorCountryId,
        })
      );
    }
  }, [dispatch, user, moderator, selectedEditorCountryId]);

  useEffect(() => {
    if (moderator?.managedCountries[0] !== editorCountry) {
      setManagedCategories([]);
    }
  }, [selectedEditorCountryId, editorCountry, moderator]);

  useEffect(() => {
    const token = JSON.parse(window.localStorage.getItem("token") || "");
    const getCountryId = () => {
      fetch("https://api.svayp.com/Admin/Countries/GetDynamic", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          Columns: [
            {
              Name: "id",
            },
          ],
          Where: {
            NAME: "name",
            VALUE: editorCountry,
            TYPE: "=",
          },
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && data.data && data.data.length > 0) {
            setSelectedEditorCountryId(data.data[0].id);
          } else {
            setSelectedEditorCountryId("");
          }
        });
    };
    getCountryId();
  }, [editorCountry]);

  // useEffect(() => {
  //   const token = JSON.parse(window.localStorage.getItem("token") || "");

  //   fetch("https://api.svayp.com/Admin/Categories/GetDynamic", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: token,
  //     },
  //     body: JSON.stringify({
  //       Columns: [
  //         {
  //           Name: "title",
  //         },
  //       ],
  //       Where: {
  //         NAME: "countryId",
  //         VALUE: selectedEditorCountryId,
  //         TYPE: "=",
  //       },
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setCategories(data.data);
  //     });
  // }, [selectedEditorCountryId]);

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const payload: AddModeratorDTO = {
      role,
      firstName,
      email,
      lastName,
      countryId: countryId,
      managedCountries,
      managedCategories,
      password,
    };
    if(moderator ? true : password !== confirmPassword){
      toast.error("Password and confirm password do not match");
      return;
    }else if (password.length < 8) {
      toast.error("Password must be at least 8 characters");
      return;
    }else if (password.length > 20) {
      toast.error("Password must be at most 20 characters");
      return;
    }else if (!password.match(/[a-z]/g)) {
      toast.error("Password must contain at least one lowercase letter");
      return;
  }else if (!password.match(/[A-Z]/g)) {
      toast.error("Password must contain at least one uppercase letter");
      return;
  }else if (!password.match(/[0-9]/g)) {
      toast.error("Password must contain at least one number");
      return;
  }else if (!password.match(/[^a-zA-Z\d]/g)) {
      toast.error("Password must contain at least one special character");
      return;
  }else if (firstName === "") {
      toast.error("First name is required");
      return;
  }else if (lastName === "") {
      toast.error("Last name is required");
      return;
  }else if (email === "") {
      toast.error("Email is required");
      return;
  }else{
    if (moderator) {
      dispatch(updateModerator({ user: { ...moderator, ...payload } }))
      .then((e) => {
        toast.success("Moderator successfully updated.");
        setRole(UserRole.ALLUSERS);
      })
      .catch(() => toast.error("Something went wrong"));
    } else {

      dispatch(createModerator({ ...payload }))
        .then((e) => {
          toast.success("Moderator successfully added.");
          setRole(UserRole.ALLUSERS);
        })
        .catch(() => toast.error("Something went wrong"));
    }
  }

    onClose();
  };

  const [managedCountries, setManagedCountries] = useState<string[]>(
    moderator?.managedCountries || []
  );

  const handleChange = (event: SelectChangeEvent<typeof managedCountries>) => {
    const {
      target: { value },
    } = event;
    setManagedCountries(typeof value === "string" ? value.split(",") : value);
  };

  const handleCategoryChange = (
    event: SelectChangeEvent<typeof managedCountries>
  ) => {
    const {
      target: { value },
    } = event;
    setManagedCategories(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "591px",
          bgcolor: "background.paper",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          onClick={onClose}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            cursor: "pointer",
            padding: "12px 16px",
          }}
        >
          <CloseIcon />
        </Box>
        <Typography variant="paragraph" color="#000" mb="24px">
          {!!moderator ? "Update" : "Create"} Moderator
        </Typography>
        <form onSubmit={submitForm}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "24px",
            }}
          >
            <TextField
              id="firstName"
              label="First name"
              required
              variant="filled"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              InputProps={
                {
                  disableUnderline: true,
                } as Partial<OutlinedInputProps>
              }
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#c2c2c2",
                  },
                },
              }}
            />
            <TextField
              id="lastName"
              label="Last name"
              variant="filled"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              InputProps={
                {
                  disableUnderline: true,
                } as Partial<OutlinedInputProps>
              }
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#c2c2c2",
                  },
                },
              }}
            />
            <TextField
              id="email"
              label="E-mail"
              variant="filled"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={
                {
                  disableUnderline: true,
                } as Partial<OutlinedInputProps>
              }
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#c2c2c2",
                  },
                },
              }}
            />
            <Select
              id="role"
              defaultValue=""
              value={role}
              onChange={(e: any) => setRole(e.target.value)}
              displayEmpty
              color={"primary"}
              inputProps={{ "aria-label": "Without label" }}
              required
            >
              <MenuItem style={{ display: "none" }} value="">
                Role
              </MenuItem>
              <MenuItem value={UserRole.SUPERADMIN}>Super Admin</MenuItem>
              <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
              <MenuItem value={UserRole.EDITOR}>Editor</MenuItem>
            </Select>

            {role === UserRole.ADMIN && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "24px",
                }}
              >
                <FormControl>
                  <InputLabel id="admincountry-multiple-checkbox-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="admincountry-multiple-checkbox-label"
                    id="admincountry-multiple-checkbox"
                    multiple
                    value={managedCountries}
                    onChange={handleChange}
                    input={<OutlinedInput label="Select Country" />}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {countries?.countries?.map((item, index) => (
                      <MenuItem key={index} value={item.name}>
                        <Checkbox
                          checked={managedCountries.indexOf(item.name) > -1}
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {role === UserRole.EDITOR && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "24px",
                }}
              >
                <Select
                  id="editorCountry"
                  defaultValue=""
                  value={editorCountry}
                  onChange={(e: any) => setEditorCountry(e.target.value)}
                  displayEmpty
                  color={"primary"}
                  inputProps={{ "aria-label": "Without label" }}
                  required
                >
                  <MenuItem style={{ display: "none" }} value="">
                    Select Country
                  </MenuItem>
                  {countries?.countries?.map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {editorCountry &&
                  (!loading ? (
                    <FormControl>
                      <InputLabel id="editorCategories-multiple-checkbox-label">
                        Select Categories
                      </InputLabel>
                      <Select
                        labelId="editorCategories-multiple-checkbox-label"
                        id="editorCategories-multiple-checkbox"
                        multiple
                        value={
                          managedCategories || moderator?.managedCategories
                        }
                        onChange={handleCategoryChange}
                        input={<OutlinedInput label="Select Categories" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {categories?.data?.map((item, index) => (
                          <MenuItem key={index} value={item.title}>
                            <Checkbox
                              checked={
                                managedCategories.indexOf(item.title) > -1
                              }
                            />
                            <ListItemText primary={item.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    renderSkeleton({ height: 52 })
                  ))}
              </Box>
            )}

              <TextField
              id="password"
              label="Password"
              name="password"
              variant="filled"
              required
              onChange={(e) => setPassword(e.target.value)}
              InputProps={
                {
                  disableUnderline: true,
                } as Partial<OutlinedInputProps>
              }
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#c2c2c2",
                  },
                },
              }}
            />
            <TextField
              id="passwordAgain"
              label="Password again"
              name="confirmPassword"
              variant="filled"
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={
                {
                  disableUnderline: true,
                } as Partial<OutlinedInputProps>
              }
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#c2c2c2",
                  },
                },
              }}
            />
            <LoadingButton loading={moderatorLoading} variant="contained" type="submit">
              <Typography variant="buttonText">Save User</Typography>
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddModeratorModal;
