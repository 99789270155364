import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MeatballsMenuIcon from "assets/cards/MeatballsMenuIcon";
import GreenTickIcon from "assets/clients/GreenTickIcon";
import CustomTable, { Column } from "components/customTable";
import Layout from "components/layout";
import { clientsData } from "mock/clientsData";
import usePagination from "utils/usePagination";
import ClientDetailModal from "./components/clientDetailModal";
import { Header } from "./styles";

const Clients = () => {
  const [filters, setFilters] = useState("");
  const [isClientDetailModal, setClientDetailModal] = useState(false);
  const [clientItem, setClientItem] = useState(null);

  const cardsArray = new Array(20).fill({});

  let [page, setPage] = useState(1);
  const PER_PAGE = 8;

  const count = Math.ceil(cardsArray.length / PER_PAGE);
  const _DATA = usePagination(cardsArray, PER_PAGE);

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const columns: Column<any>[] = [
    {
      id: "name",
      label: "NAME",
      width: "15%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.name}
        </Typography>
      ),
    },
    {
      id: "phone",
      label: "PHONE",
      width: "15%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.phone}
        </Typography>
      ),
    },
    {
      id: "email",
      label: "E-MAIL",
      width: "20%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.email}
        </Typography>
      ),
    },
    {
      id: "registeredDate",
      label: "REGISTERED DATE",
      width: "15%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.registeredDate}
        </Typography>
      ),
    },
    {
      id: "finishDate",
      label: "FINISH DATE",
      width: "10%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.finishDate}
        </Typography>
      ),
    },
    {
      id: "region",
      label: "REGION",
      width: "10%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.region}
        </Typography>
      ),
    },
    {
      id: "userType",
      label: "USER TYPE",
      width: "10%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.userType}
        </Typography>
      ),
    },
    {
      id: "request",
      label: "TALEP",
      width: "10%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.request}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "STATUS",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color="#000"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {item.status} {item.status !== "Teklif bekliyor" && <GreenTickIcon />}
        </Typography>
      ),
    },
    {
      id: "menu",
      label: "",
      width: "10%",
      render: (item) => (
        <MeatballsMenuIcon
          onClick={() => {
            setClientItem(item);
            setClientDetailModal(true);
          }}
        />
      ),
    },
  ];

  return (
    <Layout>
      <Header>
        <Typography variant="paragraph">Filters: </Typography>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={filters}
            onChange={(e: any) => setFilters(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Users</MenuItem>
            <MenuItem value="superadmin">Super Admin</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
      </Header>
      <CustomTable title="Clients" columns={columns} data={clientsData} />
      <ClientDetailModal
        open={isClientDetailModal}
        onClose={() => setClientDetailModal(false)}
        item={clientItem}
      />
    </Layout>
  );
};

export default Clients;
