import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CustomTable, { Column } from "components/customTable";
import Layout from "components/layout";
import SwitchComp from "components/switchComp";
import { User, UserRole } from "interfaces/user";
import { useAppDispatch, useAppSelector } from "services";
import {
  getModerators,
  updateModerator,
} from "services/moderatorsSlice/api";
import usePagination from "utils/usePagination";
import AddModeratorModal from "./components/addModeratorModal";
import { CustomMenu } from "./components/moderatorPopup";
import { Header } from "./styles";

export const MODERATOR_PER_PAGE = 8;

const Moderators = () => {
  const location = useLocation() as any;
  const { moderators } = useAppSelector((state) => state.moderators);
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<UserRole>(UserRole.ALLUSERS);
  const [isAddModerator, setAddModerator] = useState(false);
  const { countryId } = useAppSelector((state) => state.countries);

  useEffect(() => {
    if (location?.state?.addModerator) {
      setAddModerator(true);
      window.history.replaceState({}, document.title);
    }
  }, [location?.state?.addModerator]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getModerators({
        page,
        pageSize: MODERATOR_PER_PAGE,
        role: filters === "" ? undefined : filters,
      })
    );
  }, [page, dispatch, filters]);

  useEffect(() => {
    setPage(1);
    dispatch(
      getModerators({
        page,
        pageSize: MODERATOR_PER_PAGE,
        role: filters === "" ? undefined : filters,
      })
    );
  }, [filters, countryId, dispatch, page]);

  const count = Math.ceil(moderators.length / MODERATOR_PER_PAGE);
  const _DATA = usePagination(moderators.data, MODERATOR_PER_PAGE);

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const columns: Column<User>[] = [
    {
      id: "name",
      label: "NAME",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status ? "#000" : "#c2c2c2"}
        >
          {item.firstName + " " + item.lastName}
        </Typography>
      ),
    },
    {
      id: "email",
      label: "E-MAIL",
      width: "20%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status ? "#000" : "#c2c2c2"}
        >
          {item.email}
        </Typography>
      ),
    },
    {
      id: "registeredDate",
      label: "REGISTERED DATE",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status ? "#000" : "#c2c2c2"}
        >
          {new Date(item.registeredDate).toLocaleDateString()}
        </Typography>
      ),
    },
    // {
    //   id: "lastDate",
    //   label: "LAST DATE",
    //   width: "15%",
    //   render: (item) => (
    //     <Typography
    //       variant="paragraph"
    //       color={item.status ? "#000" : "#c2c2c2"}
    //     >
    //       {item.}
    //     </Typography>
    //   ),
    // },
    {
      id: "userType",
      label: "USER TYPE",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status ? "#000" : "#c2c2c2"}
        >
          {item.role}
        </Typography>
      ),
    },
    // {
    //   id: "duration",
    //   label: "DURATION",
    //   width: "10%",
    //   render: (item) => (
    //     <Typography
    //       variant="paragraph"
    //       color={item.status ? "#000" : "#c2c2c2"}
    //     >
    //       {item.}
    //     </Typography>
    //   ),
    // },
    {
      id: "status",
      label: "STATUS",
      width: "10%",
      render: (item) => (
        <SwitchComp
          checked={item.status === "ACTIVE"}
          setChecked={() => {
            dispatch(
              updateModerator({
                user: {
                  ...item,
                  status: item.status === "ACTIVE" ? "PASSIVE" : "ACTIVE",
                },
              })
            );
          }}
        />
      ),
    },
    {
      id: "menu",
      label: "",
      width: "10%",
      render: (item) => (
        <CustomMenu item={item} page={page} filters={filters} />
      ),
    },
  ];

  return (
    <Layout>
      <Header>
        <Typography variant="paragraph">Filters: </Typography>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={filters}
            onChange={(e: any) => setFilters(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Users</MenuItem>
            <MenuItem value={UserRole.SUPERADMIN}>Super Admin</MenuItem>
            <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
            <MenuItem value={UserRole.EDITOR}>Editor</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
        <Button variant="contained" onClick={() => setAddModerator(true)}>
          <Typography variant="buttonText">+ Add New Moderator</Typography>
        </Button>
      </Header>
      <CustomTable
        title="Registered Users (Moderators)"
        columns={columns}
        data={_DATA.currentData()}
      />
      <AddModeratorModal
        open={isAddModerator}
        onClose={() => setAddModerator(false)}
      />
    </Layout>
  );
};

export default Moderators;
