import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "components/layout";
import usePagination from "utils/usePagination";
import CategoryCard from "pages/categories/components/categoryCard";
import { CardsWrapper, Header, PassiveCardsWrapper } from "./styles";
import ArchiveCard from "./components/archiveCard";
import ContentCard from "pages/cards/components/contentCard";
import { useAppDispatch, useAppSelector } from "services";
import { Category } from "interfaces/category";
import { IMG_URL } from "constants/index";
import { getCards } from "services/cardsSlice";
import { Card } from "interfaces/card";
import { getCategories } from "services/categoriesSlice";
import LoadingSpinner from "components/loadingSpinner";

const PER_PAGE = 12;

const Archives = () => {
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState("");
  const [cardsFilter, setCardsFilter] = useState("");
  const [platform, setPlatform] = useState("");
  const [archiveCardItem, setArchiveCardItem] = useState<Category | null>(null);
  // eslint-disable-next-line
  const [categoryFilter, setCategoryFilter] = useState("");
  const user = useAppSelector((state) => state.auth.user);

  const { categories, loading } = useAppSelector((state) => state.categories);
  const { cards } = useAppSelector((state) => state.cards);
  const { countryId } = useAppSelector((state) => state.countries);
  // eslint-disable-next-line
  const filteredCategories = categoryFilter
    ? categories?.data.filter((category) => category.id === categoryFilter)
    : categories;

  let [categoryPage, setCategoryPage] = useState(1);
  let [cardPage, setCardPage] = useState(1);

  const categoryCount = Math.ceil(categories?.length / PER_PAGE);
  const cardCount = Math.ceil(cards.length / 8);
  const categoryData = usePagination<Category>(categories.data, PER_PAGE);
  const cardData = usePagination<Card>(
    cards.data.filter((card) => card.categoryId === archiveCardItem?.id),
    PER_PAGE
  );

  const handleChange = (e: any, p: number) => {
    if (archiveCardItem) {
      setCardPage(p);
      cardData?.jump(p);
    } else {
      setCategoryPage(p);
      categoryData?.jump(p);
    }
  };

  useEffect(() => {
    dispatch(getCategories({ page: categoryPage, user }));
  }, [categoryPage, dispatch, user]);

  useEffect(() => {
    dispatch(getCards({ page: cardPage, categoryId: archiveCardItem?.id, user }));
  }, [cardPage, dispatch, archiveCardItem, user]);

  useEffect(() => {
    if (archiveCardItem) {
      dispatch(getCards({ page: cardPage, categoryId: archiveCardItem?.id, user }));
    }
  }, [archiveCardItem, dispatch, user, cardPage]);

  useEffect(() => {
    setArchiveCardItem(null);
    dispatch(getCategories({ page: 1, user }));
  }, [countryId, dispatch, user]);

  return (
    <Layout>
      <Header>
        <Typography variant="paragraph">Filters: </Typography>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={filters}
            onChange={(e: any) => setFilters(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Categories</MenuItem>
            <MenuItem value="turkey">Turkey</MenuItem>
            <MenuItem value="singapore">Singapore</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={cardsFilter}
            onChange={(e: any) => setCardsFilter(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Cards</MenuItem>
            <MenuItem value="turkıye">Türkiye</MenuItem>
            <MenuItem value="gundem">Gündem</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={platform}
            onChange={(e: any) => setPlatform(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Platforms</MenuItem>
            <MenuItem value="web">Web</MenuItem>
            <MenuItem value="mobile">Mobile</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-start",
            paddingLeft: "96px",
          }}
        >
          <Pagination
            count={archiveCardItem ? cardCount : categoryCount}
            size="large"
            page={
              archiveCardItem ? cards.skip / 8 + 1 : categories.skip / 12 + 1
            }
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
      </Header>
      <CardsWrapper>
        {loading ? (
          <LoadingSpinner height={30} />
        ) : (
          !archiveCardItem &&
          categoryData
            ?.currentData()
            ?.map((item, index: number) => (
              <CategoryCard
                key={`${item.title}-${index}`}
                item={item}
                onClick={() => setArchiveCardItem(item)}
              />
            ))
        )}
        {archiveCardItem && (
          <>
            <ArchiveCard
              item={archiveCardItem}
              onClose={() => setArchiveCardItem(null)}
            />
            <PassiveCardsWrapper>
              {cardData?.currentData().map((item: any, index: number) => {
                const cardIndex =
                  cardPage > 1 ? index + 1 + (cardPage - 1) * 8 : index + 1;
                return (
                  <ContentCard
                    // imgsrc={`https://picsum.photos/450/340?random=${page}`}
                    imgsrc={IMG_URL + item.media}
                    cardIndex={cardIndex}
                    key={`card-${cardIndex}`}
                    active={item.status}
                    title={item.title}
                    data={item}
                    /*  handleDeleteCard={handleDeleteCard} */
                  />
                );
              })}
            </PassiveCardsWrapper>
          </>
        )}
      </CardsWrapper>
    </Layout>
  );
};

export default Archives;
