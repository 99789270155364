import { useState, useEffect } from "react";
import NumberOfUsers from 'components/dashboardCards/numberOfUsers/index';
import { IMG_URL } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'services/index';
import { getCategories } from "services/categoriesSlice";

const CategoryCard = () => {
    const {t} = useTranslation(['dashboard']);
    const user = useAppSelector((state) => state.auth.user);
    const dispatch = useAppDispatch();
    const { countryId } = useAppSelector((state) => state.countries);
    const [topVotedCategory, setTopVoteCategory] = useState<any>(null);
    

    const allCategories = useAppSelector((state) => state.categories.categories);

    useEffect(() => {
      dispatch(getCategories({user, getAll: true}));
    }, [countryId, user, dispatch]);


    const getTopVotedCategory = () => {
    let topVotedCategoryCount = 0; 
    // eslint-disable-next-line
    allCategories?.data?.map((category: any) => {
      const categoryTotalVote = category.optionBottomStat + category.optionTopStat + category.optionLeftStat + category.optionRightStat;
      if (categoryTotalVote > topVotedCategoryCount) {
        topVotedCategoryCount = categoryTotalVote;
        setTopVoteCategory(category);
      }
    });
  };

    useEffect(() => {
    getTopVotedCategory();
    // eslint-disable-next-line
  }, [allCategories]);


    const topVotedCategoryData = {
    id: 'topVoteCategory',
    title: t('top-voted-category'),
    subtitle: topVotedCategory?.title,
    total: topVotedCategory?.optionBottomStat + topVotedCategory?.optionTopStat + topVotedCategory?.optionLeftStat + topVotedCategory?.optionRightStat,
    image: IMG_URL + topVotedCategory?.media ,
    color: "#ffffff",
    optionTopStat: topVotedCategory?.optionTopStat,
    optionLeftStat: topVotedCategory?.optionLeftStat,
    optionRightStat: topVotedCategory?.optionRightStat,
    voteCard: true,
  }

  return (
    <NumberOfUsers data={topVotedCategoryData} />
  )
}

export default CategoryCard