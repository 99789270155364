import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "components/layout";
import usePagination from "utils/usePagination";
import CategoryCard from "./components/categoryCard";
import { CardsWrapper, Header } from "./styles";
import CategoryModal from "./components/categoryModal";
import { useAppDispatch, useAppSelector } from "services";
import { Category } from "interfaces/category";
import { getCategories } from "services/categoriesSlice";
import CropperModal from "./components/cropperModal";
import { useLocation } from "react-router-dom";

export const CATEGORY_PER_PAGE = 12;

const Categories = () => {
  const dispatch = useAppDispatch();
  const location = useLocation() as any;
  const [categoryFilter, setCategoryFilter] = useState("");
  const [isAddCategory, setAddCategory] = useState(false);
  const [categoryImg, setCategoryImg] = useState<any>(null);
  const [croppedImgSrc, setCroppedImgSrc] = useState("");
  const [isCropperModal, setCropperModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const user = useAppSelector((state) => state.auth.user);
  

  useEffect(() => {
    if (location?.state?.addCategory) {
      setAddCategory(true);
      window.history.replaceState({}, document.title);
    }
  }, [location?.state?.addCategory]);

  const { categories } = useAppSelector((state) => state.categories);
  const { countryId } = useAppSelector((state) => state.countries);
  // eslint-disable-next-line
  const filteredCategories = categoryFilter
    ? categories?.data?.filter((category) => category.id === categoryFilter)
    : categories;

  let [page, setPage] = useState(1);

  const count = Math.ceil(categories?.length / CATEGORY_PER_PAGE);
  const _DATA = usePagination<Category>(categories.data, CATEGORY_PER_PAGE);

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA?.jump(p);
  };
  useEffect(() => {
    dispatch(getCategories({ page, user }));
    // eslint-disable-next-line
  }, [page, countryId]);
  return (
    <Layout>
      <Header>
        <Typography variant="paragraph">Filters: </Typography>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={categoryFilter}
            onChange={(e: any) => setCategoryFilter(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
          >
            <MenuItem value="">All Categories</MenuItem>
            {categories.data?.map((category, index: number) => (
              <MenuItem key={category.id} value={category.id}>
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            setSelectedCategory(undefined);
            setAddCategory(true);
          }}
        >
          <Typography variant="buttonText">+ Add New Category</Typography>
        </Button>
      </Header>
      <CardsWrapper>
        {_DATA?.currentData()?.map((item, index: number) => (
          <CategoryCard
            key={`${item.title}-${index}`}
            item={item}
            onClick={() => {
              setSelectedCategory(item);
              setAddCategory(true);
            }}
          />
        ))}
      </CardsWrapper>
      <CategoryModal
        open={isAddCategory}
        onClose={() => setAddCategory(false)}
        categoryImg={categoryImg}
        croppedImgSrc={croppedImgSrc}
        setCategoryImg={setCategoryImg}
        setCropperModal={setCropperModal}
        category={selectedCategory}
      />
      <CropperModal
        open={isCropperModal}
        onClose={() => setCropperModal(false)}
        imageSrc={categoryImg}
        setCroppedImgSrc={setCroppedImgSrc}
      />
    </Layout>
  );
};

export default Categories;
